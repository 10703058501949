import { createSlice } from "@reduxjs/toolkit";
import { setCurrentLanguage } from "../lang";

export const Slice = createSlice({
  name: "user",
  initialState: {
    email: null,
    password: null,
    token: null,
    results: [],
  },
  reducers: {
    login: (state, action) => {
      window.localStorage.setItem("token", action.payload.access);
      return { ...state, ...action.payload };
    },
    save: (state, action) => {
      window.localStorage.setItem("user", JSON.stringify(action.payload));
      return { ...state, ...action.payload };
    },
    setUserToken: (state, action) => {
      return { ...state, token: action.payload };
    },
    removeUserToken: (state, action) => {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user");
      return {};
    },
    setLanguage: (state, action) => {
      setCurrentLanguage(action.payload);
      return { ...state, lang: action.payload };
    },
    setUserId: (state, action) => {
      return { ...state, id: action.payload };
    },
    doNothing: (state, action) => {
      return state;
    },
  },
});

export const reducerActions = Slice.actions;

// const user = useSelector(state => state.user);
// const id = user.id (or)
// const { email } = useSelector((state) => state.user);

export default Slice.reducer;
