import { useEffect } from "react";
import { X } from "lucide-react";

const Modal = ({
  onClose,
  children,
  visible,
  setVisible,
  title,
  footer,
}: any) => {
  // Use useEffect to add an event listener to the document
  useEffect(() => {
    function onKeyDown(event: any) {
      if (event.keyCode === 27) {
        // Close the modal when the Escape key is pressed
        onClose();
      }
    }

    // Prevent scolling
    document.body.style.overflow = "hidden";
    document.addEventListener("keydown", onKeyDown);

    // Clear things up when unmounting this component
    return () => {
      document.body.style.overflow = "visible";
      document.removeEventListener("keydown", onKeyDown);
    };
  });
  if (visible) {
    return (
      <div className="fixed overflow-auto left-0 bottom-0 top-0 right-0 z-50 bg-black bg-opacity-70">
        <div className="bg-white rounded max-w-full mx-auto my-14 p-4 w-4/5 lg:w-2/3">
          <div className="flex flex-row">
            <div className="grow">{title}</div>
            <div
              className="flex-none cursor-pointer"
              onClick={() => setVisible(false)}
            >
              <X className="h-4 w-4" />
              <span className="sr-only">Close</span>
            </div>
          </div>
          {children}
          {footer}
        </div>
      </div>
    );
  }

  return null;
};

export default Modal;
