import React from "react";
import { Facebook, Twitter } from "lucide-react";

const SocialIcons = () => {
  return (
    <div className="hidden md:flex gap-3 justify-self-end">
      <a
        href="https://www.facebook.com/profile.php?id=100093053563596&mibextid=LQQJ4d"
        target="_blank"
      >
        <Facebook className="stroke-primary" />
      </a>
      <a
        href="https://twitter.com/medexirusa?s=11&t=OKbV6JIru4EIt1pcZ3h2pg"
        target="_blank"
      >
        <Twitter className="stroke-primary" />
      </a>
    </div>
  );
};

export default SocialIcons;
