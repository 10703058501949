import { useEffect } from "react";
import { useParams } from "react-router-dom";

const Index = () => {
  const { id } = useParams();
  useEffect(() => {
    window.location.href = `/dashboard/consultations/${id}/files`;
  }, []);
  return null;
};

export default Index;
