import { createSlice } from "@reduxjs/toolkit";

const reducer = (entity) => {
  const Slice = createSlice({
    name: entity,
    initialState: {
      list: [],
      meta: false,
      selected: false,
      counter: 0,
    },
    reducers: {
      setStats: (state, action) => {
        return {
          ...state,
          ...action.payload,
        };
      },
      setAll: (state, action) => {
        return {
          ...state,
          list: action.payload.results,
        };
      },
      setSelected: (state, action) => {
        return {
          ...state,
          selected: action.payload,
        };
      },
      setCounter: (state, action) => {
        return {
          ...state,
          counter: action.payload,
        };
      },
      delete: (state, action) => {
        return {
          ...state,
          list: state.list.filter((item) => item.id !== action.payload),
        };
      },
      setMeta: (state, action) => {
        return {
          ...state,
          meta: { ...state.meta, ...action.payload },
        };
      },
      edit: (state, action) => {
        return {
          ...state,
          selected: action.payload,
          list: state.list.map((item) =>
            item.id === action.payload.id ? action.payload : item,
          ),
        };
      },
      add: (state, action) => {
        return {
          ...state,
          list: [...state.list, action.payload],
        };
      },
      select: (state, action) => {
        return {
          ...state,
          selected: state.list.find((item) => item.id === action.payload),
        };
      },
      setAllBySubfield: (state, action) => {
        return {
          ...state,
          list: action.payload.managed_patients,
        };
      },
    },
  });
  return {
    reducer: Slice.reducer,
    reducerActions: Slice.actions,
  };
};

export default reducer;
