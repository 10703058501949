import React, { useState } from "react";
import { useParams, navigate, useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Input, Form, Alert } from "antd";
import { Link } from "react-router-dom";
import __ from "../../lang";
import * as actions from "../../actions/user-actions";
import medLogoen from "../../assets/img/login/clink_logo_03.png";
import medLogoar from "../../assets/img/login/clink_logo_03.png";

const ForgotPassword = () => {
  const { uid, token } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const onFinish = (values) => {
    values.uid = uid;
    values.token = token;
    actions.resetPasswordConfirmAction(values, (res, isError) => {
      if (!isError) {
        navigate("/login");
      } else {
        setError(res.message);
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const logoOnChangelang = () => {
    const lang = window.localStorage.getItem("default_language") || "ar";
    if (lang === "ar") {
      return (
        <img
          className="mx-auto h-10 w-auto"
          src={medLogoar}
          alt="Your Company"
        />
      );
    } else {
      return (
        <img
          className="mx-auto h-10 w-auto"
          src={medLogoen}
          alt="Your Company"
        />
      );
    }
  };

  return (
    <div className="bg-white flex justify-center items-center py-16 p-4">
      <div className="container mx-auto md:w-1/4 bg-white shadow-xl rounded-md overflow-hidden">
        <div className="w-full flex justify-center items-center">
          <div className="w-full flex flex-col justify-center">
            <div className="bg-primary p-4">{logoOnChangelang()}</div>
            <div className="p-4">
              <p className="text-lg text-center text-primary mb-4">
                Please enter your new password to continue!
              </p>
              <Form
                name="basic"
                layout="vertical"
                initialValues={{}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                className="flex flex-col mt-2"
              >
                {error && (
                  <Alert className="mt-2" message={error} type="error" />
                )}
                <Form.Item
                  label={null}
                  name="new_password"
                  rules={[
                    {
                      required: true,
                      message: __("please.enter.password"),
                    },
                  ]}
                >
                  <Input.Password
                    placeholder={__("enter.password")}
                    className="rounded py-2 px-3 leading-tight  appearance-none border-2 border-slate-100
                      focus:outline-none focus:bg-white focus:border-blue-600 text-gray-600"
                  />
                </Form.Item>
                <button
                  className="bg-primary hover:bg-primary-light transition duraion-100 p-2 text-white rtl:font-kufi rounded"
                  type="submit"
                >
                  Send
                </button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
