import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Form, Input } from "antd";
import { Alert, AlertDescription, AlertTitle } from "../../shadcn/alert";
import * as actions from "../../actions/utils-actions";
import __ from "../../lang";

const VerifyPhone = () => {
  const [form] = Form.useForm();
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const onFinish = (values) => {
    actions.verifyPhoneAction(values, (res, error) => {
      console.log(error);
      if (!error) {
        navigate("/dashboard");
      } else {
        setError(__("invalid-token"));
      }
    });
  };

  return (
    <div className="grow container items-center gap-8 md:gap-32 p-4 h-fit">
      <div className="w-full md:w-2/3 shadow-lg p-8 bg-white h-fit mb-4 mx-auto">
        {error && (
          <Alert variant="destructive" className="mb-8 bg-red-100">
            <AlertTitle>Ooops Error</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        <Form form={form} name="verifyPhone" onFinish={onFinish}>
          <div className="text-2xl text-primary  mb-5 flex w-auto justify-center">
            {__("verify.phone")}
          </div>
          <p className="text-base font-medium leading-6 text-gray-900 inline-block">
            {__("please.verify.your.phone")}
          </p>
          <Form.Item
            name="token"
            rules={[
              {
                required: true,
                message: __("verify.phone.required"),
              },
            ]}
          >
            <Input
              type="text"
              className="border-1 border-primary rounded-lg bg-slate-100"
            />
          </Form.Item>
          <div className="flex flex-col gap-4">
            <Button
              block
              type="primary"
              className="bg-primary"
              htmlType="submit"
            >
              {__("continue")}
            </Button>
            <Button type="primary" block className="mt-2 bg-primary">
              <Link to="/Services">{__("back")}</Link>
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default VerifyPhone;
