import React from "react";
import SocialIcons from "./SocialIcons";
import __ from "../../lang";

const Footer = (props) => {
  return (
    <div>
      <footer className="bg-gray-900 text-white">
        <div className="container mx-auto p-4">
          <div
            className="text-primary grid grid-cols-1 md:grid-cols-3 gap-8 justify-start justify-items-start
                    text-center pt-2 text-gray-400 text-sm"
          >
            <span>© 2023 {__("rights")}</span>
            <span>{__("terms.policy")}</span>
            <SocialIcons />
            <br />
            <br />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
