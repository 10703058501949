import axios from 'axios';
import { UseAction } from './base-actions';
import { reducerActions } from '../reducers/user';

export const loginAction = (reqPayload, cb) =>
    UseAction({
        url: `auth/jwt/create/`,
        method: 'post',
        action: reducerActions.login,
        reqPayload,
        cb
    });

export const resetPasswordAction = (reqPayload, cb) =>
    UseAction({
        url: `auth/users/reset_password/`,
        method: 'post',
        action: reducerActions.doNothing,
        reqPayload,
        cb
    });

export const resetPasswordConfirmAction = (reqPayload, cb) =>
    UseAction({
        url: `auth/users/reset_password_confirm/`,
        method: 'post',
        action: reducerActions.doNothing,
        reqPayload,
        cb
    });

export const registerAction = (reqPayload, cb) =>
    UseAction({
        url: `auth/users/`,
        method: 'post',
        action: reducerActions.register,
        reqPayload,
        cb
    });

export const meAction = (cb) =>
    UseAction({
        url: `auth/users/me/`,
        method: 'get',
        action: reducerActions.save,
        cb
    });

export const registerUserTokenAction = (reqPayload, cb) =>
    UseAction({
        url: `auth/jwt/create/`,
        method: 'post',
        action: reducerActions.register,
        reqPayload,
        cb
    });

export const createNewPassword = (reqPayload, cb) =>
    UseAction({
        url: `auth/users/set_password/`,
        method: 'post',
        action: reducerActions.save,
        reqPayload,
        cb
    });

export const DemographicsPatientAction = (reqPayload, cb) =>
    UseAction({
        url: `v1/users/users/${reqPayload.id}/`,
        method: 'patch',
        action: reducerActions.save,
        reqPayload,
        cb
    });

export const specialtiesPatientAction = (cb) =>
    UseAction({
        url: `v1/users/specialties/`,
        method: 'get',
        action: reducerActions.setSpecialties,
        cb
    });

export const specialtiesPatientOneAction = (reqPayload, cb) =>
    UseAction({
        url: `v1/users/specialties/${reqPayload.id}`,
        method: 'get',
        action: reducerActions.save,
        reqPayload,
        cb
    });

export const setUserTokenAction = (cb) =>
    UseAction({
        normalAction: true,
        action: reducerActions.setUserToken,
        payload: window.localStorage.getItem('token'),
        cb
    });

export const removeUserTokenAction = (cb) =>
    UseAction({
        normalAction: true,
        action: reducerActions.removeUserToken,
        payload: {},
        cb
    });

export const saveAction = (reqPayload, cb) =>
    UseAction({
        url: `v1/me/${reqPayload.email}/`,
        method: 'patch',
        action: reducerActions.save,
        reqPayload,
        cb
    });

export const setLanguage = (lang, cb) =>
    UseAction({
        normalAction: true,
        action: reducerActions.setLanguage,
        payload: lang,
        cb
    });

export const setUserIdAction = (id, cb) =>
    UseAction({
        normalAction: true,
        action: reducerActions.setUserId,
        payload: id,
        cb
    });

//Refractor later on
export const checkUserIsPatient = (reqPayload, cb) =>
    UseAction({
        url: `v1/users/users/${reqPayload.id}/?expand=patients/`,
        method: 'get',
        action: reducerActions.save,
        reqPayload,
        cb
    });

//Refractor later on
//api/v1/users/patients
export const createNewPatient = (reqPayload, cb) =>
    UseAction({
        url: `v1/users/patients/`,
        method: 'post',
        action: reducerActions.save,
        reqPayload,
        cb
    });
