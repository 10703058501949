import React from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHospitalUser,
  faClipboardQuestion,
  faAddressCard,
} from "@fortawesome/free-solid-svg-icons";
import Card from "../../components/card";
import __ from "../../lang";

const Index = ({ active }) => {
  const { pathname } = useLocation();

  return (
    <div className="grid lg:grid-cols-6 grid-cols-2 gap-4 flex-none">
      <Link to={"/dashboard"}>
        <Card
          className={
            pathname === "/dashboard" || active === "patients"
              ? "border-primary text-primary"
              : ""
          }
        >
          <FontAwesomeIcon
            className="text-3xl text-slate-400"
            icon={faHospitalUser}
          />
          <span className={"text-md font-semibold"}>{__("patients")}</span>
        </Card>
      </Link>
      <Link to={"/dashboard/consultations"}>
        <Card
          className={
            pathname === "/dashboard/consultations" ||
            active === "consultations"
              ? "border-primary text-primary"
              : ""
          }
        >
          <FontAwesomeIcon
            className="text-3xl text-slate-400"
            icon={faClipboardQuestion}
          />
          <span className={"text-md font-semibold"}>{__("consultations")}</span>
        </Card>
      </Link>
      <Link to={"/dashboard/profile"}>
        <Card
          className={
            pathname === "/dashboard/profile" || active === "profile"
              ? "border-primary text-primary"
              : ""
          }
        >
          <FontAwesomeIcon
            className="text-3xl text-slate-400"
            icon={faAddressCard}
          />
          <span className={"text-md font-semibold text-center"}>
            {__("profile")}
          </span>
        </Card>
      </Link>
    </div>
  );
};

export default Index;
