import { createSlice } from "@reduxjs/toolkit";

export const Slice = createSlice({
  name: "utils",
  initialState: {},
  reducers: {
    doNothing: (state, action) => {
      return state;
    },
  },
});

export const reducerActions = Slice.actions;

export default Slice.reducer;
