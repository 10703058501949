import FeaturesservicesTwo from "../../assets/img/Home/about_img.jpeg";
import MissionStatement from "../../assets/img/Home/doctor_pa.jpeg";
import VisionStatement from "../../assets/img/Home/why_us_img.jpeg";
import OurVision from "../../assets/img/Home/our_vision_4.png";
import WhyUs from "../../assets/img/Home/why_us_4.png";
import HowWorks from "../../assets/img/Home/how_it_works_3.jpg";
import OurStory from "../../assets/img/Home/Mu_doctor.jpeg";
import AboutBlock from "./about-block";

const blocksList = [
  {
    heading: "welcome.clink",
  },
  {
    title: "why.us",
    image: WhyUs,
    content: ["why.text"],
  },
  {
    title: "about.us",
    subtitle: "care.for",
    image: FeaturesservicesTwo,
    content: ["p.about.us"],
  },
  {
    title: "mission.statement",
    image: MissionStatement,
    content: ["mission.statement.text"],
  },
  {
    title: "vision.statement",
    image: VisionStatement,
    content: ["our.vision.statement"],
  },
  {
    title: "our.vision",
    image: OurVision,
    content: ["our.vision.text"],
  },
  {
    heading: "power.caption",
  },
  {
    title: "how.works",
    subtitle: "seeking.mena",
    image: HowWorks,
    attachment: "read.more",
  },
  {
    heading: "tag.line",
  },
  {
    title: "our.story",
    image: OurStory,
    content: ["our.story1", "our.story2", "our.story3", "our.story4"],
  },
];

const About = () => {
  return (
    <div className="p-4 container mx-auto flex-col flex gap-4">
      {blocksList.map((block, index) => (
        <AboutBlock key={index} {...block} />
      ))}
    </div>
  );
};

export default About;
