import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import {
  faPlus,
  faTrashCan,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../shadcn/form";
import { Avatar, AvatarFallback, AvatarImage } from "../../shadcn/avatar";
import { Label } from "../../shadcn/label";
import { RadioGroup, RadioGroupItem } from "../../shadcn/radio-group";
import * as utilsActions from "../../actions/utils-actions";
import Card from "../../components/card";
import DashboardNav from "../../components/dashboard-nav";
import { Button } from "../../shadcn/button";
import actionsFactory from "../../actions/main-actions";
import { cn } from "../../lib/utils";
import __ from "../../lang";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../shadcn/table";
import { Calendar } from "../../shadcn/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../../shadcn/popover";
import Dialog from "../../shadcn/dialog";
import { Input } from "../../shadcn/input";
import { useNavigate } from "react-router-dom";
import { DayPicker } from "react-day-picker";
import "./datecss.css";

const FormSchema = z.object({
  first_name: z.string({
    required_error: __("first.name.required"),
    invalid_type_error: __("first.name.string"),
  }),
  last_name: z.string({
    required_error: __("last.name.required"),
    invalid_type_error: __("last.name.string"),
  }),
  gender: z.string({
    required_error: __("gender.required"),
    invalid_type_error: __("gender.name.string"),
  }),
  dob: z.date(),
});

const Index = () => {
  const actions = actionsFactory("users/patients");
  const user = useSelector((state) => state.user);
  const { list } = useSelector((state) => state.patients);
  const [currentPatient, setCurrentPatient] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const form = useForm({ resolver: zodResolver(FormSchema) });
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (user && user.id) {
      actions.searchAction(`?managed_by=${user.id}`);
    }
  }, [user]);

  const onFinish = (values) => {
    values.managed_by = user.id;
    values.created_at = format(new Date(), "yyyy-MM-dd");
    values.dob = format(values.dob, "yyyy-MM-dd");
    actions.addAction(values, (res, error) => {
      if (!error) {
        setShowModal(false);
        form.reset();
        actions.searchAction(`?managed_by=${user.id}`);
      }
    });
  };

  const onDelete = (item) => {
    actions.deleteAction(item);
  };

  // const onUpload = (item) => {
  //   setCurrentPatient(item);
  //   fileInputRef
  //     .current
  //     .click();
  // };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    utilsActions.uploadPatientImage(currentPatient.id, formData, () => {
      actions.searchAction(`?managed_by=${user.id}`);
    });
  };

  return (
    <div className="grow container mx-auto items-center gap-8 md:gap-32 px-4 py-8 h-fit">
      <div className="flex flex-col gap-4">
        <DashboardNav />
        <Card className="items-start gap-8 grow" noHover={true}>
          <div className="actions flex flex-row w-full items-end justify-end">
            <Button
              onClick={() => setShowModal(true)}
              className="text-white gap-4 text-md"
            >
              <FontAwesomeIcon icon={faPlus} />
              {__("add.patient")}
            </Button>
          </div>
          <Table>
            <TableCaption>{__("list.recent.patients")}</TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="text-right ltr:text-left"></TableHead>
                <TableHead className="text-right ltr:text-left">
                  {__("first.name")}
                </TableHead>
                <TableHead className="text-right ltr:text-left">
                  {__("last.name")}
                </TableHead>
                <TableHead className="text-right ltr:text-left">
                  {__("gender")}
                </TableHead>
                <TableHead className="text-right ">
                  {__("date.birth")}
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {list.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Avatar>
                      <AvatarImage src={item.image} alt="@shadcn" />
                      <AvatarFallback>
                        {item.first_name.charAt(0)}
                        {item.last_name.charAt(0)}
                      </AvatarFallback>
                    </Avatar>
                  </TableCell>
                  <TableCell className="font-medium">
                    {item.first_name}
                  </TableCell>
                  <TableCell>{item.last_name}</TableCell>
                  <TableCell>{item.gender}</TableCell>
                  <TableCell className="text-right">{item.dob}</TableCell>
                  <div className="flex flex-row gap-2 justify-end mt-4">
                    {/* <Button onClick={() => onUpload(item)} className="gap-2" variant="outline">
                      <FontAwesomeIcon icon={faUpload}/> {__('avatar')}
                    </Button> */}
                    <Button
                      onClick={() => onDelete(item)}
                      className="gap-2"
                      variant="destructive"
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                      {__("delete.patient")}
                    </Button>
                  </div>
                </TableRow>
              ))}
              <input
                onChange={handleFileChange}
                ref={fileInputRef}
                type="file"
                className="hidden"
              />
            </TableBody>
          </Table>
        </Card>
        {showModal === true && (
          <Dialog visible={showModal} setVisible={setShowModal}>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onFinish)}
                className="space-y-6"
              >
                <FormField
                  control={form.control}
                  name="first_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{__("first.name")}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="last_name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{__("last.name")}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="dob"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel>{__("date.birth")}</FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant={"outline"}
                              className={cn(
                                "pl-3 text-left font-normal",
                                !field.value && "text-muted-foreground",
                              )}
                            >
                              {field.value ? (
                                format(field.value, "PPP")
                              ) : (
                                <span>{__("pick.date")}</span>
                              )}
                              <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                          <Calendar
                            mode="single"
                            selected={field.value}
                            onSelect={field.onChange}
                            disabled={(date) =>
                              date > new Date() || date < new Date("1900-01-01")
                            }
                            initialFocus
                            captionLayout="dropdown"
                            fromYear={1930}
                            toYear={2030}
                          />
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="gender"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{__("gender")}</FormLabel>
                      <FormControl>
                        <RadioGroup
                          className="flex flex-row gap-4"
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                        >
                          <div className="flex items-center space-x-2">
                            <RadioGroupItem value="M" id="M" />
                            <Label htmlFor="M">{__("male")}</Label>
                          </div>
                          <div className="flex items-center space-x-2">
                            <RadioGroupItem value="F" id="F" />
                            <Label htmlFor="F">{__("female")}</Label>
                          </div>
                        </RadioGroup>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button className="text-white" type="submit">
                  {__("submit.patient")}
                </Button>
              </form>
            </Form>
          </Dialog>
        )}
      </div>
    </div>
  );
};

export default Index;
