import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import {
    faPlus,
    faCreditCard,
    faTrashCan
} from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Badge } from '../../shadcn/badge';
import Card from '../../components/card';
import DashboardNav from '../../components/dashboard-nav';
import Select from '../../components/select';
import { Button } from '../../shadcn/button';
import actionsFactory from '../../actions/main-actions';
import __ from '../../lang';
import Dialog from '../../shadcn/dialog';
import { Input } from '../../shadcn/input';
import { Textarea } from '../../shadcn/textarea';
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '../../shadcn/table';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from '../../shadcn/form';

const FormSchema = z.object({
    title: z.string({
        required_error: 'Title is required',
        invalid_type_error: 'Title must be a string'
    }),
    issue: z.string().optional(),
    patient: z.string(),
    specialty: z.string(),
    questions: z.string().optional()
});

const Index = (props) => {
    const navigate = useNavigate();
    const actions = actionsFactory('meetings/consultation');
    const actionsSpecialty = actionsFactory('users/specialties');
    const actionsPatients = actionsFactory('users/patients');
    const actionsPrices = actionsFactory('payments/prices');
    const user = useSelector((state) => state.user);
    const { list } = useSelector((state) => state.consultations);
    const { list: patients } = useSelector((state) => state.patients);
    const { list: specialties } = useSelector((state) => state.specialties);
    const [showModal, setShowModal] = useState(false);
    const [consultation, setConsultation] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const form = useForm({ resolver: zodResolver(FormSchema) });
    const lang = window.localStorage.getItem('default_language') || 'ar';
    let statusMap = {};
    const filteredSpecialtiesEn = specialties.find(
        (item) => item.name_en === 'Oncology'
    );
    const filteredSpecialtiesAr = specialties.find(
        (item) => item.name_ar === 'الأورام'
    );

    if (lang === 'en') {
        statusMap = {
            0: 'Under review by case manager',
            1: 'Under review by consultant',
            2: 'Info is missing',
            3: 'Case manager contacted patient',
            4: 'Completed'
        };
    } else if (lang === 'ar') {
        statusMap = {
            0: 'قيد المراجعة من قبل مدير الحالة',
            1: 'قيد المراجعة من قبل المستشار',
            2: 'بعض المعلومات مفقودة',
            3: 'مدير الحالة اتصل بالمريض',
            4: 'اكتملت الحاله و أغلقت'
        };
    }

    useEffect(() => {
        actions.searchAction('?expand=patient/');
        actionsSpecialty.getAll();
        actionsPrices.searchAction('?active=true');
    }, []);

    useEffect(() => {
        if (user && user.id) {
            actionsPatients.searchAction(`?managed_by=${user.id}`);
        }
    }, [user]);

    const onFinish = (values) => {
        actions.addAction(values, (res, error) => {
            if (!error) {
                setShowModal(false);
                form.reset();
                actions.searchAction('?expand=patient/');
                onShowPayment(res.data);
            }
        });
    };

    const onDelete = (item) => {
        actions.deleteAction(item);
    };

    const onShowPayment = (item) => {
        setShowPaymentModal(true);
        setConsultation(item);
        navigate('/services', { state: item });
    };

    return (
        <div className="grow container mx-auto items-center gap-8 md:gap-32 px-4 py-8 h-fit">
            <div className="flex flex-col gap-4">
                <DashboardNav />
                <Card className="items-start gap-8 grow" noHover={true}>
                    <div className="actions flex flex-row w-full items-end justify-end gap-2">
                        <Button className="rounded-lg inline-flex items-center lg:h-11 text-sm lg:text-md h-[4rem] text-white bg-primary hover:bg-primary-light">
                            <a
                                href="https://wa.me/+16307060362"
                                target="_blank"
                            >
                                <FontAwesomeIcon icon={['fab', 'whatsapp']} />
                                <span className="px-2">
                                    {__('consultation.help')}
                                </span>
                            </a>
                        </Button>
                        <Button
                            onClick={() => setShowModal(true)}
                            className="text-white lg:h-11 gap-4 text-sm lg:text-md h-[4rem]"
                        >
                            <FontAwesomeIcon icon={faPlus} />
                            {__('add.consultation')}
                        </Button>
                    </div>
                    <Table>
                        <TableCaption>
                            {__('list.recent.consultations')}
                        </TableCaption>
                        <TableHeader>
                            <TableRow>
                                <TableHead></TableHead>
                                <TableHead className="rtl:text-right">
                                    {__('title')}
                                </TableHead>
                                <TableHead className="rtl:text-right">
                                    {__('first.name')}
                                </TableHead>
                                <TableHead className="rtl:text-right">
                                    {__('last.name')}
                                </TableHead>
                                <TableHead className="rtl:text-right">
                                    {__('status')}
                                </TableHead>
                                <TableHead className="text-right">
                                    {__('date.created')}
                                </TableHead>
                                <TableHead className="text-center">
                                    {__('action')}
                                </TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {list.map((item, index) => (
                                <TableRow
                                    key={index}
                                    className={
                                        [0, 2].indexOf(item.payment_status) !==
                                        -1
                                            ? 'grayscale relative'
                                            : ''
                                    }
                                >
                                    {[0, 2].indexOf(item.payment_status) !==
                                    -1 ? (
                                        <div className="absolute z-10 top-0 bottom-0 left-0 right-0 items-center justify-center font-bold text-black flex bg-white opacity-[0.85]">
                                            {__('consultation.paid')}
                                        </div>
                                    ) : (
                                        <TableCell className="font-medium"></TableCell>
                                    )}
                                    <TableCell className="font-medium">
                                        <Button variant="link" asChild>
                                            <Link
                                                to={`/dashboard/consultations/${item.id}`}
                                            >
                                                {item.title}
                                            </Link>
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        {item.patient.first_name}
                                    </TableCell>
                                    <TableCell>
                                        {item.patient.last_name}
                                    </TableCell>
                                    <TableCell>
                                        <Badge variant="secondary">
                                            {statusMap[item.status]}
                                        </Badge>
                                    </TableCell>
                                    <TableCell className="text-right">
                                        {item.created_at.split('T')[0]}
                                    </TableCell>
                                    <TableCell className="text-right">
                                        <div className="flex flex-row gap-2 justify-end">
                                            {[0, 2].indexOf(
                                                item.payment_status
                                            ) !== -1 && (
                                                <Button
                                                    className="gap-2 z-50 relative"
                                                    variant="outline"
                                                    onClick={() =>
                                                        onShowPayment(item)
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faCreditCard}
                                                    />
                                                    {__('pay')}
                                                </Button>
                                            )}
                                            <Button
                                                onClick={() => onDelete(item)}
                                                className="gap-2"
                                                variant="destructive"
                                            >
                                                <FontAwesomeIcon
                                                    icon={faTrashCan}
                                                />
                                                {__('delete.consultation')}
                                            </Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Card>
                {showModal === true && (
                    <Dialog visible={showModal} setVisible={setShowModal}>
                        <Form {...form}>
                            <form
                                onSubmit={form.handleSubmit(onFinish)}
                                className="space-y-4"
                            >
                                <FormField
                                    control={form.control}
                                    name="title"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>
                                                {__('title.case')}
                                            </FormLabel>
                                            <FormControl>
                                                <Input
                                                    placeholder={__(
                                                        'title.case'
                                                    )}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <FormField
                                        control={form.control}
                                        name="patient"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>
                                                    {__('name.patients')}
                                                </FormLabel>
                                                <FormControl>
                                                    <Select
                                                        field={field}
                                                        placeholder={__(
                                                            'choose.patient'
                                                        )}
                                                        valueField="id"
                                                        label={(item) =>
                                                            `${item.first_name} ${item.last_name}`
                                                        }
                                                        items={patients}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    {lang === 'ar' && (
                                        <FormField
                                            control={form.control}
                                            name="specialty"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>
                                                        {__('specialty')}
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Select
                                                            field={field}
                                                            placeholder={__(
                                                                'choose.specialty'
                                                            )}
                                                            valueField="id"
                                                            label={(item) =>
                                                                `${item.name_ar}`
                                                            }
                                                            items={[
                                                                filteredSpecialtiesAr
                                                            ]}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    )}
                                    {lang === 'en' && (
                                        <FormField
                                            control={form.control}
                                            name="specialty"
                                            render={({ field }) => (
                                                <FormItem>
                                                    <FormLabel>
                                                        {__('specialty')}
                                                    </FormLabel>
                                                    <FormControl>
                                                        <Select
                                                            field={field}
                                                            placeholder={__(
                                                                'choose.specialty'
                                                            )}
                                                            valueField="id"
                                                            label={(item) =>
                                                                `${item.name_en}`
                                                            }
                                                            items={[
                                                                filteredSpecialtiesEn
                                                            ]}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                    )}
                                </div>
                                <FormField
                                    control={form.control}
                                    name="issue"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>
                                                {__('description')}
                                            </FormLabel>
                                            <FormControl>
                                                <Textarea
                                                    rows={6}
                                                    placeholder={__(
                                                        'description'
                                                    )}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="questions"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>
                                                {__('questions.consultant')}
                                            </FormLabel>
                                            <FormControl>
                                                <Textarea
                                                    rows={6}
                                                    placeholder={__(
                                                        'questions.consultant'
                                                    )}
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <Button className="text-white" type="submit">
                                    {__('submit.consultation')}
                                </Button>
                            </form>
                        </Form>
                    </Dialog>
                )}
            </div>
        </div>
    );
};

export default Index;
