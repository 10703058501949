import React from "react";

const Index = ({ children, className, noHover }) => {
  return (
    <div
      className={`bg-white rounded shadow p-8 cursor-pointer flex flex-col gap-2 items-center border ${
        noHover ? "" : "hover:border hover:border-slate-500"
      } ${className}`}
    >
      {children}
    </div>
  );
};

export default Index;
