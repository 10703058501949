import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

import __ from '../../lang';
import actionsFactory from '../../actions/main-actions';

const Index = () => {
    const lang = window.localStorage.getItem('default_language') || 'ar';
    const { list } = useSelector((state) => state.users);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 6;
    const lastIndex = currentPage * recordsPerPage;
    const FirstIndex = lastIndex - recordsPerPage;
    const records = list.slice(FirstIndex, lastIndex);
    const npage = Math.ceil(list.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const actions = actionsFactory('users/users');

    useEffect(() => {
        actions.searchAction(
            '?type=consultant&expand=specialties,sub_specialties,languages'
        );
    }, []);

    function perpage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    function changePage(id) {
        setCurrentPage(id);
    }

    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1);
        }
    }

    return (
        <div className="container mx-auto flex flex-col items-center p-4">
            <div className="text-center mt-12">
                <form>
                    <div className="flex justify-center">
                        <div className="relative w-96 ">
                            {/* <input
                type="search"
                className="block rtl:mr-8 p-2.5 w-full z-20 text-lg text-gray-400 bg-gray-50 rounded-r-lg
                  border border-gray-200 focus:ring-blue-500 rounded-md focus:border-blue-500 dark:text-white
                  dark:focus:border-blue-500"
                placeholder={__('choose.medical.speciaity')}/> */}
                            {/* <button
                type="submit"
                className="absolute top-0 right-0 h-full p-2.5 text-sm font-medium
                 text-white bg-primary rounded-r-lg border border-primary  hover:bg-blue-800 focus:ring-4 focus:outline-none
                 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"> */}
                            {/* <svg className="w-4 h-4" fill="none" viewBox="0 0 20 20">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                </svg>
                <span className="sr-only">Search</span> */}
                            {/* </button> */}
                        </div>
                    </div>
                </form>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 mt-12 gap-4 p-4">
                {records.map((item) => (
                    <div className="flex flex-col rounded p-4">
                        <div className="flex flex-col gap-4">
                            <img
                                src={item.image}
                                alt=""
                                className="h-[22rem] w-full"
                            />
                            <p className="text-base text-center font-semibold">
                                {item.first_name} {item.last_name}
                            </p>
                            <p className="text-base text-primary">
                                {__('speciality')}
                            </p>
                            {lang === 'ar' &&
                                item.specialties.map((i) => (
                                    <li className="h-8">{i.name_ar}</li>
                                ))}
                            {lang === 'en' &&
                                item.specialties.map((i) => (
                                    <li className="h-8">{i.name_en}</li>
                                ))}
                            <p className="text-base text-primary">
                                {__('subspecialty')}
                            </p>
                            {lang === 'ar' &&
                                item.sub_specialties.map((j) => (
                                    <li className="h-8">{j.name_ar}</li>
                                ))}
                            {lang === 'en' &&
                                item.sub_specialties.map((i) => (
                                    <li className="h-8">{i.name_en}</li>
                                ))}
                            <p className="text-base text-primary">
                                {__('current.job')}
                            </p>
                            {lang === 'ar' && (
                                <li className="h-10">{item.current_job_ar}</li>
                            )}
                            {lang === 'en' && (
                                <li className="h-10">{item.current_job_en}</li>
                            )}
                            <div className="flex flex-row">
                                <div className="flex flex-col">
                                    <p className="text-primary  text-base mb-1 ">
                                        {__('nationality')}
                                    </p>
                                    <p className="text-primary text-base mb-1 ">
                                        {__('languages')}
                                    </p>
                                </div>
                                {lang === 'ar' && (
                                    <div className="flex flex-col items-center ml-6 rtl:mr-8">
                                        <span className="mb-1 ">
                                            {item.nationality_ar}
                                        </span>
                                        {item.languages.map((i) => (
                                            <span className="mb-1  inline-flex">
                                                {i.name_ar}
                                            </span>
                                        ))}
                                    </div>
                                )}
                                {lang === 'en' && (
                                    <div className="flex flex-col items-center ml-6">
                                        <span className="mb-1">
                                            {item.nationality_en}
                                        </span>
                                        {item.languages.map((i) => (
                                            <span className="mb-1  inline-flex">
                                                {i.name_en}
                                            </span>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <button className="text-white bg-slate-800 rounded-md mx-8 py-2">
                                <Link to="/login">
                                    {__('start.the.consultant')}
                                </Link>
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <nav>
                <ul className="inline-flex -space-x-px text-sm">
                    <li>
                        <a
                            href="#"
                            onClick={perpage}
                            className="flex items-center justify-center px-4 h-10 ml-0 leading-tight
                     text-gray-500 bg-white border border-gray-300 ltr:rounded-l-lg rtl:rounded-r-lg
                      hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700
                       dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                            {__('pre')}
                        </a>
                    </li>
                    {numbers.map((n, i) => (
                        <li
                            className={`page-item ${
                                currentPage === n ? 'active' : ''
                            }`}
                            key={i}
                        >
                            <a
                                href="#"
                                className="flex items-center justify-center px-4 h-10 leading-tight active:bg-red-500 text-gray-500
           bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800
            dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                onClick={() => changePage(n)}
                            >
                                {n}
                            </a>
                        </li>
                    ))}
                    <li>
                        <a
                            href="#"
                            onClick={nextPage}
                            className="flex items-center justify-center px-4 h-10 leading-tight
                 text-gray-500 bg-white border border-gray-300 ltr:rounded-r-lg rtl:rounded-l-lg hover:bg-gray-100
             hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700
                 dark:hover:text-white"
                        >
                            {__('next')}
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default Index;
