import React from "react";
import __ from "../../lang";

const AboutUs = () => {
  return (
    <div className="p-4 grow container mx-auto ">
      <div className="flex gap-4 flex-col w-full">
        <h5 className="text-xl mt-8 text-primary-light">{__("about.us")}</h5>
        <h2 className="text-3xl leading-relaxed font-bold">{__("care.for")}</h2>
        <p className="leading-loose">{__("p.about.us")}</p>
      </div>
    </div>
  );
};

export default AboutUs;
