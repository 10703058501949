import { combineReducers } from "redux";

import user from "./user";
import utils from "./utils";
import reducer from "../reducers/main";

const rootReducer = combineReducers({
  user,
  utils,
  users: reducer("users/users").reducer,
  patients: reducer("users/patients").reducer,
  consultations: reducer("meetings/consultation").reducer,
  consultationsFiles: reducer("meetings/consultation-files").reducer,
  specialties: reducer("users/specialties").reducer,
  prices: reducer("payments/prices").reducer,
  consultant: reducer("users/consultant").reducer,

  // specialties: reducer('users/specialties').reducer,
  // patient: reducer('users/patients').reducer,
  // patientsList: reducer('users/users').reducer,
  // consultation: reducer('meetings/consultation').reducer,
  // selectedPatient: reducer('selectedpatientInfo').reducer,
  // counter: reducer('counterActions').reducer
});

export default rootReducer;
