import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PhoneInput from 'react-phone-number-input';
import { getCountryCallingCode } from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import ar from 'react-phone-number-input/locale/ar.json';
import jwt_decode from 'jwt-decode';
import { Button, Checkbox, Form, Input, Result, Select } from 'antd';
import Dialog from '../../shadcn/dialog';
import * as actions from '../../actions/user-actions';
import __ from '../../lang';
import 'react-phone-number-input/style.css';
import { getCountries } from 'react-phone-number-input';

const specialitiesOptions = [
    'Surgery',
    'Oncology',
    'Cardiology',
    'Endocrinology',
    'Rheumatology',
    'Pulmonary',
    'Orthopedics',
    'Pediatric',
    'Gynecology'
];

const RegisterVolunteer = () => {
    const getSortedCountries = () => {
        const countries = getCountries();
        return countries.sort((a, b) => {
            const countryA = ar[a].toLowerCase();
            const countryB = ar[b].toLowerCase();
            return countryA.localeCompare(countryB);
        });
    };

    const user = useSelector((state) => state.user);
    const [form] = Form.useForm();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [value, setValue] = useState();
    const [country, setCountry] = useState('');
    const [showModal, setShowModel] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const currentUserlang =
        window.localStorage.getItem('default_language') || 'ar';
    const [speciailityArray, setSpeciailityArray] = useState('Pneumonia');
    const filteredSpecialityArray = specialitiesOptions.filter(
        (o) => !speciailityArray.includes(o)
    );
    const [role, setRole] = useState('Provider');

    useEffect(() => {
        const hasClearedToken = localStorage.getItem('hasClearedToken');
        if (!hasClearedToken) {
            // Clear the token or perform any other necessary actions
            // Replace the following line with your token clearing logic
            localStorage.removeItem('token');

            // Set the flag indicating the token has been cleared
            localStorage.setItem('hasClearedToken', 'true');
        }
    }, []);

    const getIdFromJwt = (userLoginFields) => {
        actions.loginAction(userLoginFields, (res, error) => {
            if (
                error === 'Request failed with status code 400' ||
                error === 'Request failed with status code 401' ||
                (res &&
                    (res.message === 'Request failed with status code 400' ||
                        res.message === 'Request failed with status code 401'))
            ) {
                setErrorMessage(
                    <Result
                        status="error"
                        title={__('registration.failed')}
                        title2={__('registration.failed.2')}
                    />
                );
                setShowModel(true);
            } else {
                const decode = jwt_decode(res.data.access);
                window.localStorage.setItem('token', res.data.access);
                actions.setUserIdAction(decode.user_id);
            }
        });
    };

    const onFinish = (values) => {
        setErrorMessage(false);
        values.country = en[country] || ar[country];
        values.phone = value;
        values.type = 'patient';
        const userLoginFields = {
            email,
            password
        };
        actions.registerAction(values, (res, error) => {
            if (
                res &&
                (res.message === 'Request failed with status code 400' ||
                    res.message === 'Request failed with status code 401' ||
                    (res.response &&
                        res.response.data.email[0] ===
                            'user with this email already exists.'))
            ) {
                setErrorMessage(
                    <Result status="error" title={__('email.exists')} />
                );
                setShowModel(true);
            } else {
                getIdFromJwt(userLoginFields);
                setShowModel(true);
            }
        });
    };

    const typeOfUser = () => {
        if (user && user.id !== undefined) {
            window.location.href = '/verify-phone';
        } else {
            window.location.href = '/';
        }
    };

    const onFinishFailed = () => {
        setErrorMessage(
            <Result
                status="error"
                title={__('registration.failed')}
                title2={__('registration.failed.2')}
            />
        );
        setShowModel(true);
    };

    return (
        <div className="grow container gap-8 md:gap-32 mx-auto p-4">
            <div className="w-full mx-auto md:w-2/3 shadow-lg p-8 bg-white h-fit mb-4">
                <p className="text-4xl text-primary mb-2 flex w-auto justify-center">
                    {__('register-volunteer')}
                </p>
                <Form
                    form={form}
                    name="register"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={{
                        remember: true
                    }}
                >
                    <p className="text-base font-medium leading-6 text-gray-900 inline-block">
                        {__('first.name')}
                    </p>
                    <Form.Item
                        name="first_name"
                        className="text-base font-medium text-gray-900"
                        rules={[
                            {
                                required: true,
                                message: __('first.name.required')
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <p className="text-base font-medium leading-6 text-gray-900 inline-block">
                        {__('last.name')}
                    </p>
                    <Form.Item
                        name="last_name"
                        className="text-base font-medium  text-gray-900"
                        rules={[
                            {
                                required: true,
                                message: __('last.name.required')
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <p className="text-base font-medium leading-6 text-gray-900 inline-block">
                        {__('email')}
                    </p>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: __('not.valid.email')
                            },
                            {
                                required: true,
                                message: __('input.email')
                            }
                        ]}
                    >
                        <Input
                            className="inline-flex"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Item>
                    <p className="text-base font-medium leading-6 text-gray-900 inline-block">
                        {__('password')}
                    </p>
                    <Form.Item
                        hasFeedback
                        name="password"
                        rules={[
                            {
                                min: 8,
                                required: true,
                                message: __('validation.password')
                            }
                        ]}
                    >
                        <Input.Password
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Item>
                    <p className="text-base font-medium leading-6 text-gray-900 inline-block">
                        {__('confirm.password')}
                    </p>
                    <Form.Item
                        hasFeedback
                        name="confirm"
                        dependencies={['password']}
                        rules={[
                            {
                                min: 8,
                                required: true,
                                message: __('please.confirm.password')
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        getFieldValue('password') === value
                                    ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(__('password.not.match'))
                                    );
                                }
                            })
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <p className="text-base font-medium leading-6 text-gray-900 inline-block">
                        {__('role')}
                    </p>
                    <Form.Item
                        name="role"
                        className="text-base font-medium text-gray-900"
                        rules={[
                            {
                                required: true,
                                message: __('role.required')
                            }
                        ]}
                    >
                        <select
                            className="rounded-lg border-2 w-full h-10 focus:ring focus:ring-blue-100"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            required
                        >
                            <option disabled selected>
                                {__('select.role')}
                            </option>
                            <option value="provider">{__('provider')}</option>
                            <option value="nurse">{__('nurse')}</option>
                            <option value="other">{__('other')}</option>
                        </select>
                    </Form.Item>
                    <p className="text-base font-medium leading-6 text-gray-900 inline-block">
                        {__('speciality')}
                    </p>
                    <Form.Item
                        name="speciality"
                        className="text-base font-medium text-gray-900"
                        rules={[
                            {
                                required: true,
                                message: __('speciality.required')
                            }
                        ]}
                    >
                        <Select
                            mode="multiple"
                            placeholder="Choose all that applies"
                            value={speciailityArray}
                            onChange={setSpeciailityArray}
                            style={{
                                width: '100%'
                            }}
                            options={filteredSpecialityArray.map((item) => ({
                                value: item,
                                label: item
                            }))}
                        />
                    </Form.Item>
                    <p className="text-base font-medium leading-6 text-gray-900 inline-block">
                        {__('subspecialty')}
                    </p>
                    <Form.Item
                        name="subspecialty"
                        className="text-base font-medium text-gray-900"
                        rules={[
                            {
                                required: true,
                                message: __('subspecialty.required')
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <p className="text-base font-medium leading-6 text-gray-900 inline-block">
                        {__('years.of.Experience')}
                    </p>
                    <Form.Item
                        name="years_of_experience"
                        className="text-base font-medium text-gray-900"
                        rules={[
                            {
                                required: true,
                                message: __('years_of_experience.required')
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <p className="text-base font-medium leading-6 text-gray-900 inline-block">
                        {__('country')}
                    </p>
                    {currentUserlang === 'en' && (
                        <Form.Item
                            name="country"
                            className="text-base font-medium text-gray-900 focus:outline-none"
                            rules={[
                                {
                                    required: true,
                                    message: __('country.required')
                                }
                            ]}
                        >
                            <select
                                className="rounded-lg border-2 w-full h-10 focus:ring focus:ring-blue-100"
                                value={country}
                                onChange={(event) =>
                                    setCountry(event.target.value || undefined)
                                }
                            >
                                <option value="">{en['ZZ']}</option>
                                {getCountries().map((country) => (
                                    <option key={country} value={country}>
                                        {en[country]}&nbsp;&nbsp;
                                        {getCountryCallingCode(country)}
                                    </option>
                                ))}
                            </select>
                        </Form.Item>
                    )}
                    {currentUserlang === 'ar' && (
                        <Form.Item
                            name="country"
                            className="text-base font-medium  text-gray-900 focus:outline-none"
                            rules={[
                                {
                                    required: true,
                                    message: __('country_required')
                                }
                            ]}
                        >
                            <select
                                className="rounded-lg border-2 w-full h-10 focus:ring focus:ring-blue-100"
                                value={country}
                                onChange={(event) =>
                                    setCountry(event.target.value || undefined)
                                }
                            >
                                <option value="">{ar['ZZ']}</option>
                                {getSortedCountries().map((country) => (
                                    <option key={country} value={country}>
                                        {ar[country]}&nbsp;&nbsp;
                                        {getCountryCallingCode(country)}
                                    </option>
                                ))}
                            </select>
                        </Form.Item>
                    )}
                    <p className="text-base font-medium leading-6 text-gray-900 inline-block">
                        {__('phone')}
                    </p>
                    <Form.Item
                        className="focus:ring-violet-300 read-only:left-0"
                        dir="ltr"
                    >
                        <PhoneInput
                            className="rounded-lg border-2 w-full h-10 p-1"
                            international
                            defaultCountry={country}
                            value={value}
                            onChange={setValue}
                        />
                    </Form.Item>

                    <p className="text-base font-medium leading-6 text-gray-900 inline-block">
                        {__('how.hear')}
                    </p>
                    <Form.Item
                        name="how_hear"
                        className="text-base font-medium  text-gray-900"
                    >
                        <Select name="how_hear">
                            <Select.Option value="google search">
                                {__('google.search')}
                            </Select.Option>
                            <Select.Option value="refered by a Friend">
                                {__('by.friend')}
                            </Select.Option>
                            <Select.Option value="refered by a Doctor">
                                {__('by.doctor')}
                            </Select.Option>
                            <Select.Option value="twiter">
                                {__('twiter')}
                            </Select.Option>
                            <Select.Option value="instagram">
                                {__('instagram')}
                            </Select.Option>
                            <Select.Option value="snapchat">
                                {__('snapchat')}
                            </Select.Option>
                            <Select.Option value="facebook">
                                {__('facebook')}
                            </Select.Option>
                            <Select.Option value="whatsApp">
                                {__('whatsApp')}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value
                                        ? Promise.resolve()
                                        : Promise.reject(
                                              new Error(__('accept.agreement'))
                                          )
                            }
                        ]}
                    >
                        <Checkbox>{__('accept.policy')}</Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            block
                            type="primary"
                            className="bg-primary hover:bg-primary-light"
                            htmlType="submit"
                        >
                            {__('register')}
                        </Button>
                    </Form.Item>
                </Form>
                {showModal === true && (
                    <Dialog
                        visible={showModal}
                        setVisible={setShowModel}
                        footer={
                            !errorMessage && (
                                <div className="flex flex-row justify-end gap-4">
                                    <button
                                        type="button"
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        onClick={() => setShowModel(false)}
                                    >
                                        {__('close')}
                                    </button>
                                    <button
                                        type="button"
                                        className="bg-primary text-white active:bg-primary-light font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        onClick={() => typeOfUser()}
                                    >
                                        {__('continue')}
                                    </button>
                                </div>
                            )
                        }
                    >
                        {errorMessage || (
                            <Result
                                status="success"
                                title={__('registration.successful')}
                                title2={__('registration.successful.2')}
                            />
                        )}
                    </Dialog>
                )}
            </div>
        </div>
    );
};
export default RegisterVolunteer;
