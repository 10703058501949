import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Input, Form, Alert } from "antd";
import { Link } from "react-router-dom";
import __ from "../../lang";
import * as actions from "../../actions/user-actions";
import medLogoen from "../../assets/img/login/clink_logo_03.png";
import medLogoar from "../../assets/img/login/clink_logo_03.png";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const onFinish = (values) => {
    actions.resetPasswordAction(values, (res, isError) => {
      if (isError) {
        setError(res.message);
      } else {
        setSuccess(
          "Reset password was sent successfully  to you email, please check your email and follow the instructions there",
        );
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const logoOnChangelang = () => {
    const lang = window.localStorage.getItem("default_language") || "ar";
    if (lang === "ar") {
      return (
        <img
          className="mx-auto h-10 w-auto"
          src={medLogoar}
          alt="Your Company"
        />
      );
    } else {
      return (
        <img
          className="mx-auto h-10 w-auto"
          src={medLogoen}
          alt="Your Company"
        />
      );
    }
  };

  return (
    <div className="bg-white flex justify-center items-center py-16 p-4">
      <div className="container mx-auto md:w-1/4 bg-white shadow-xl rounded-md overflow-hidden">
        <div className="w-full flex justify-center items-center">
          <div className="w-full flex flex-col justify-center">
            <div className="bg-primary p-4">{logoOnChangelang()}</div>
            <div className="p-4">
              <p className="text-lg text-center text-primary mb-4">
                {__("password.enter")}
              </p>
              {error && <Alert className="mt-2" message={error} type="error" />}
              {success && (
                <Alert className="mt-2" message={success} type="success" />
              )}
              {!success && (
                <Form
                  name="basic"
                  layout="vertical"
                  initialValues={{}}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  className="flex flex-col mt-2"
                >
                  <Form.Item
                    label={null}
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: __("please.enter.email"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={__("enter.email")}
                      className="rounded py-2 px-3 leading-tight mt-2 appearance-none border-2 border-slate-100
                        focus:outline-none focus:bg-white focus:border-blue-600 text-gray-600"
                    />
                  </Form.Item>
                  <button
                    type="submit"
                    className="bg-primary hover:bg-primary-light transition duraion-100 p-2 text-white rtl:font-kufi rounded"
                  >
                    {__("submit")}
                  </button>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
