import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../components/header/index';
import Home from '../pages/home';
import Login from '../pages/login';
import ForgotPassword from '../pages/forgot-password';
import ForgotPasswordConfirm from '../pages/forgot-password-confirm';
import Footer from '../components/footer/index';
import Register from '../pages/register';
import Success from '../pages/success';
import VerifyPhone from '../pages/verify-phone';
import Dashboard from '../pages/dashboard';
import Consultations from '../pages/consultations';
import ConsultationDetail from '../pages/consultations/detail';
import Profile from '../pages/profile';
import Plans from '../pages/plans';
import AboutUs from '../pages/about-us';
import ContactUs from '../pages/contact-us';
import HowItWorks from '../pages/how-it-works';
import Consultants from '../components/consultants';
import RegisterVolunteer from '../pages/register-volunteer';
import Faq from '../pages/faq';
import Services from '../pages/services';
import * as actions from '../actions/user-actions';

function Index() {
    const location = useLocation();
    const path = location.pathname;
    const [token, setToken] = useState(window.localStorage.getItem('token'));
    const { email } = useSelector((state) => state.user);
    console.log(token, email);
    useEffect(() => {
        window.scroll(1, 1);
    }, [path]);

    useEffect(() => {
        if (token) {
            actions.meAction();
        }
    }, [token]);

    const currentlang = window.localStorage.getItem('default_language') || 'ar';

    const routes = [
        {
            path: '/',
            element: <Home />,
            header: true,
            footer: true,
            requiredAuth: false
        },
        {
            path: '/login',
            element: <Login />,
            header: true,
            footer: true,
            requiredAuth: false
        },
        {
            path: '/forgot-password',
            element: <ForgotPassword />,
            header: false,
            footer: false,
            requiredAuth: false
        },
        {
            path: '/password/reset/confirm/:uid/:token',
            element: <ForgotPasswordConfirm />,
            header: false,
            footer: false,
            requiredAuth: false
        },
        {
            path: '/register',
            element: <Register />,
            header: true,
            footer: true,
            requiredAuth: false,
            disableOnLogin: true
        },
        {
            path: '/register-volunteer',
            element: <RegisterVolunteer />,
            header: true,
            footer: true,
            requiredAuth: false,
            disableOnLogin: true
        },
        {
            path: '/verify-phone',
            element: <VerifyPhone />,
            header: true,
            footer: true,
            requiredAuth: true
        },
        {
            path: '/dashboard',
            element: <Dashboard />,
            header: true,
            footer: true,
            requiredAuth: true
        },
        {
            path: '/dashboard/consultations',
            element: <Consultations />,
            header: true,
            footer: true,
            requiredAuth: true
        },
        {
            path: '/dashboard/consultations/:id',
            element: <ConsultationDetail />,
            header: true,
            footer: true,
            requiredAuth: true
        },
        {
            path: '/dashboard/consultations/:id/files',
            element: <ConsultationDetail files={true} />,
            header: true,
            footer: true,
            requiredAuth: true
        },
        {
            path: '/dashboard/profile',
            element: <Profile />,
            header: true,
            footer: true,
            requiredAuth: true
        },
        {
            path: '/success/:id',
            element: <Success />,
            header: true,
            footer: true,
            requiredAuth: true
        },
        {
            path: '/plans',
            element: <Plans />,
            header: true,
            footer: true,
            requiredAuth: false
        },
        {
            path: '/about-us',
            element: <AboutUs />,
            header: true,
            footer: true,
            requiredAuth: false
        },
        {
            path: '/contact-us',
            element: <ContactUs />,
            header: true,
            footer: true,
            requiredAuth: false
        },
        {
            path: '/how-it-works',
            element: <HowItWorks />,
            header: true,
            footer: true,
            requiredAuth: false
        },
        {
            path: '/consultants',
            element: <Consultants />,
            header: true,
            footer: true,
            requiredAuth: false
        },
        {
            path: '/faq',
            element: <Faq />,
            header: true,
            footer: true,
            requiredAuth: false
        },
        {
            path: '/services',
            element: <Services />,
            header: true,
            footer: true,
            requiredAuth: false
        }
    ];

    const renderRouter = () => {
        if (token || email) {
            return (
                <Routes>
                    {routes
                        .filter((item) => item.disableOnLogin)
                        .map((route) => {
                            return (
                                <Route
                                    path={route.path}
                                    element={<Navigate to="/" />}
                                />
                            );
                        })}
                    {routes
                        .filter((item) => !item.disableOnLogin)
                        .map((route) => {
                            const renderedRoute = [];
                            if (route.header) {
                                renderedRoute.push(<Header />);
                            }
                            renderedRoute.push(route.element);
                            if (route.footer) {
                                renderedRoute.push(<Footer />);
                            }
                            return (
                                <Route
                                    path={route.path}
                                    element={renderedRoute}
                                />
                            );
                        })}
                </Routes>
            );
        }
        return (
            <Routes>
                {routes
                    .filter((item) => !item.requiredAuth)
                    .map((route) => {
                        const renderedRoute = [];
                        if (route.header) {
                            renderedRoute.push(<Header />);
                        }
                        renderedRoute.push(route.element);
                        if (route.footer) {
                            renderedRoute.push(<Footer />);
                        }
                        return (
                            <Route path={route.path} element={renderedRoute} />
                        );
                    })}
                <Route path="*" element={<Login />} />
            </Routes>
        );
    };

    return (
        <div
            className="rtl:font-kufi min-h-screen flex flex-col"
            dir={currentlang === 'en' ? 'ltr' : 'rtl'}
        >
            {renderRouter()}
        </div>
    );
}

export default Index;
