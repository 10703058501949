import React from "react";
import __ from "../../lang";

const Faq = () => {
  return (
    <div className="py-20 max-w-screen-lg mx-auto">
      <div className="text-center mb-16">
        <h3 className="text-3xl sm:text-4xl leading-normal text-indigo-600 font-extrabold tracking-tight">
          {__("frequently.question")}
        </h3>
      </div>
      <div className="px-10 sm:px-16 sm:flex items-start mb-10">
        <div className="w-9/12">
          <div className="flex items-start mb-8">
            <div
              className="hidden sm:flex items-center justify-center p-3 mr-3 rtl:ml-3 rounded-full bg-indigo-500
               text-white border-4 border-white text-xl font-semibold"
            >
              <svg
                width="24px"
                fill="white"
                height="24px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g data-name="Layer 2">
                  <g data-name="menu-arrow">
                    <rect
                      width="24"
                      height="24"
                      transform="rotate(180 12 12)"
                      opacity="0"
                    />
                    <path d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z" />
                    <circle cx="12" cy="19" r="1" />
                  </g>
                </g>
              </svg>
            </div>
            <div className="text-md">
              <h1 className="text-gray-900 font-semibold mb-2">
                {__("q.first.opinion")}
              </h1>
              <p className="text-gray-500 text-sm">{__("a.first.opinion")}</p>
            </div>
          </div>
          <div className="flex items-start mb-8">
            <div
              className="hidden sm:flex items-center justify-center p-3 mr-3 rtl:ml-3 rounded-full bg-indigo-500
               text-white border-4 border-white text-xl font-semibold"
            >
              <svg
                width="24px"
                fill="white"
                height="24px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g data-name="Layer 2">
                  <g data-name="menu-arrow">
                    <rect
                      width="24"
                      height="24"
                      transform="rotate(180 12 12)"
                      opacity="0"
                    />
                    <path d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z" />
                    <circle cx="12" cy="19" r="1" />
                  </g>
                </g>
              </svg>
            </div>
            <div className="text-md">
              <h1 className="text-gray-900 font-semibold mb-2">
                {__("q.second.opinion")}
              </h1>
              <p className="text-gray-500 text-sm mb-2">
                {__("a.second.opinion.a")}
              </p>
              <p className="text-gray-500 text-sm mb-2">
                {__("a.second.opinion.b")}
              </p>
              <p className="text-gray-500 text-sm mb-2">
                {__("a.second.opinion.c")}
              </p>
              <p className="text-gray-500 text-sm ">
                {__("a.second.opinion.d")}
              </p>
            </div>
          </div>
          <div className="flex items-start mb-8">
            <div
              className="hidden sm:flex items-center justify-center p-3 mr-3 rtl:ml-3 rounded-full bg-indigo-500
               text-white border-4 border-white text-xl font-semibold"
            >
              <svg
                width="24px"
                fill="white"
                height="24px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g data-name="Layer 2">
                  <g data-name="menu-arrow">
                    <rect
                      width="24"
                      height="24"
                      transform="rotate(180 12 12)"
                      opacity="0"
                    />
                    <path d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z" />
                    <circle cx="12" cy="19" r="1" />
                  </g>
                </g>
              </svg>
            </div>
            <div className="text-md">
              <h1 className="text-gray-900 font-semibold mb-2">
                {__("q.third.opinion")}
              </h1>
              <p className="text-gray-500 text-sm">{__("a.third.opinion")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="px-6 sm:px-16 sm:flex items-start">
        <div className="w-9/12">
          <div className="flex items-start mb-8">
            <div
              className="hidden sm:flex items-center justify-center p-3 mr-3 rtl:ml-3 rounded-full bg-indigo-500
               text-white border-4 border-white text-xl font-semibold"
            >
              <svg
                width="24px"
                fill="white"
                height="24px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g data-name="Layer 2">
                  <g data-name="menu-arrow">
                    <rect
                      width="24"
                      height="24"
                      transform="rotate(180 12 12)"
                      opacity="0"
                    />
                    <path d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z" />
                    <circle cx="12" cy="19" r="1" />
                  </g>
                </g>
              </svg>
            </div>
            <div className="text-md">
              <h1 className="text-gray-900 font-semibold mb-2">
                {__("q.fourth.opinion")}
              </h1>
              <p className="text-gray-500 text-sm">{__("a.fourth.opinion")}</p>
            </div>
          </div>
          <div className="flex items-start mb-8">
            <div
              className="hidden sm:flex items-center justify-center p-3 mr-3 rtl:ml-3 rounded-full bg-indigo-500
               text-white border-4 border-white text-xl font-semibold"
            >
              <svg
                width="24px"
                fill="white"
                height="24px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g data-name="Layer 2">
                  <g data-name="menu-arrow">
                    <rect
                      width="24"
                      height="24"
                      transform="rotate(180 12 12)"
                      opacity="0"
                    />
                    <path d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z" />
                    <circle cx="12" cy="19" r="1" />
                  </g>
                </g>
              </svg>
            </div>
            <div className="text-md">
              <h1 className="text-gray-900 font-semibold mb-2">
                {__("q.fifth.opinion")}
              </h1>
              <p className="text-gray-500 text-sm">{__("a.fifth.opinion")}</p>
            </div>
          </div>
          <div className="flex items-start mb-8">
            <div
              className="hidden sm:flex items-center justify-center p-3 mr-3 rtl:ml-3 rounded-full bg-indigo-500
               text-white border-4 border-white text-xl font-semibold"
            >
              <svg
                width="24px"
                fill="white"
                height="24px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g data-name="Layer 2">
                  <g data-name="menu-arrow">
                    <rect
                      width="24"
                      height="24"
                      transform="rotate(180 12 12)"
                      opacity="0"
                    />
                    <path
                      d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 
                    5 0 0 0 17 9z"
                    />
                    <circle cx="12" cy="19" r="1" />
                  </g>
                </g>
              </svg>
            </div>
            <div className="text-md">
              <h1 className="text-gray-900 font-semibold mb-2">
                {__("q.sixth.opinion")}
              </h1>
              <p className="text-gray-500 text-sm">{__("a.sixth.opinion")}</p>
            </div>
          </div>
          <div className="flex items-start mb-8">
            <div
              className="hidden sm:flex items-center justify-center p-3 mr-3 rtl:ml-3 rounded-full bg-indigo-500 text-white
               border-4 border-white text-xl font-semibold"
            >
              <svg
                width="24px"
                fill="white"
                height="24px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g data-name="Layer 2">
                  <g data-name="menu-arrow">
                    <rect
                      width="24"
                      height="24"
                      transform="rotate(180 12 12)"
                      opacity="0"
                    />
                    <path d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z" />
                    <circle cx="12" cy="19" r="1" />
                  </g>
                </g>
              </svg>
            </div>
            <div className="text-md">
              <h1 className="text-gray-900 font-semibold mb-2">
                {__("q.seventh.opinion")}
              </h1>
              <p className="text-gray-500 text-sm">{__("a.seventh.opinion")}</p>
            </div>
          </div>

          <div className="flex items-start mb-8">
            <div
              className="hidden sm:flex items-center justify-center p-3 mr-3 rtl:ml-3 rounded-full bg-indigo-500 text-white
               border-4 border-white text-xl font-semibold"
            >
              <svg
                width="24px"
                fill="white"
                height="24px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g data-name="Layer 2">
                  <g data-name="menu-arrow">
                    <rect
                      width="24"
                      height="24"
                      transform="rotate(180 12 12)"
                      opacity="0"
                    />
                    <path d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z" />
                    <circle cx="12" cy="19" r="1" />
                  </g>
                </g>
              </svg>
            </div>
            <div className="text-md">
              <h1 className="text-gray-900 font-semibold mb-2">
                {__("q.eighth.opinion")}
              </h1>
              <p className="text-gray-500 text-sm">{__("a.eighth.opinion")}</p>
            </div>
          </div>

          <div className="flex items-start mb-8">
            <div
              className="hidden sm:flex items-center justify-center p-3 mr-3 rtl:ml-3 rounded-full bg-indigo-500 text-white 
              border-4 border-white text-xl font-semibold"
            >
              <svg
                width="24px"
                fill="white"
                height="24px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g data-name="Layer 2">
                  <g data-name="menu-arrow">
                    <rect
                      width="24"
                      height="24"
                      transform="rotate(180 12 12)"
                      opacity="0"
                    />
                    <path d="M17 9A5 5 0 0 0 7 9a1 1 0 0 0 2 0 3 3 0 1 1 3 3 1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-1.1A5 5 0 0 0 17 9z" />
                    <circle cx="12" cy="19" r="1" />
                  </g>
                </g>
              </svg>
            </div>
            <div className="text-md">
              <h1 className="text-gray-900 font-semibold mb-2">
                {__("q.ninth.opinion")}
              </h1>
              <p className="text-gray-500 text-sm">{__("a.ninth.opinion")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
