import React from "react";
import __ from "../../lang";
import Georgia from "../../assets/icon/logos/georgia_reagents.jpeg";
import Michigan from "../../assets/icon/logos/michigan_state.jpeg";
import Rochester from "../../assets/icon/logos/rochester_unity.jpg";
import Uic from "../../assets/icon/logos/UIC-MEDICINE-LOGO.png";
import Iwoa from "../../assets/icon/logos/university_of_iowa.jpeg";
import Aace from "../../assets/icon/socities/aace.jpeg";
import Acp from "../../assets/icon/socities/acp.png";
import Asco from "../../assets/icon/socities/asco-acs-logo.jpg";
import Endocrine from "../../assets/icon/socities/endocrine_society.png";

const Carousel = () => {
  return (
    <div className="p-4">
      <p className="text-3xl flex justify-center font-bold text-primary mb-8">
        {__("title.one")}
      </p>
      <div className="container mx-auto flex flex-col items-center bg-white rounded">
        <div className="grid grid-cols-1 md:grid-cols-5 items-center m-8 gap-x-0 md:gap-x-20 gap-y-8  md:gap-y-0 p-8">
          <img src={Georgia} alt="" />
          <img src={Michigan} alt="" />
          <img src={Rochester} alt="" />
          <img src={Uic} alt="" />
          <img src={Iwoa} alt="" />
        </div>
      </div>
      <br />
      <p className="text-3xl flex justify-center font-bold text-primary mb-8">
        {__("title.two")}
      </p>
      <div className="container mx-auto flex flex-col items-center bg-white rounded">
        <div className="grid grid-cols-1 items-center md:grid-cols-4 m-8 gap-x-0 md:gap-x-20 gap-y-8  md:gap-y-0 p-8">
          <img src={Aace} alt="" />
          <img src={Acp} alt="" />
          <img src={Asco} alt="" />
          <img src={Endocrine} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Carousel;
