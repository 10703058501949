import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { US } from "country-flag-icons/react/3x2";
import { SA } from "country-flag-icons/react/3x2";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Dialog, Disclosure, Popover } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Facebook, Twitter } from "lucide-react";
import { Button, Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import Envelope from "../../assets/envelope.png";
import medLogoen from "../../assets/img/login/clink_logo_03.png";
import medLogoar from "../../assets/img/login/clink_logo_03.png";
import * as actions from "../../actions/user-actions";
import __, { getCurrentLanguage } from "../../lang";
import whatsapp from "../../assets/icon/whatsapp.png";

const onLanguageChange = ({ key }) => {
  actions.setLanguage(key, () => {
    window.location.reload();
  });
};

const renderLanuageMenu = () => {
  return (
    <Menu onClick={onLanguageChange}>
      <Menu.Item key={"ar"} className="font-ar">
        <SA
          title={__("ar")}
          style={{
            width: "50px",
            height: "30px",
            paddingLeft: "15px",
          }}
        />
      </Menu.Item>
      <Menu.Item key={"en"}>
        <US
          title={__("en")}
          style={{
            width: "50px",
            height: "30px",
            paddingLeft: "15px",
          }}
        />
      </Menu.Item>
    </Menu>
  );
};

const Header = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    library.add(fab);
  }, []);

  const onLogout = () => {
    actions.removeUserTokenAction();
    setMobileMenuOpen(false);
    navigate("/");
  };

  const logoOnChangelang = () => {
    const lang = window.localStorage.getItem("default_language") || "ar";
    if (lang === "ar") {
      return (
        <img
          className="h-12 w-auto pt-4 yes, thats th"
          src={medLogoar}
          alt=""
        />
      );
    } else {
      return <img className="h-12 w-auto pt-4 " src={medLogoen} alt="" />;
    }
  };

  const scrollHowWorks = () => {
    window.scrollTo(0, 4600);
  };

  const scrollAbout = () => {
    window.scrollTo(0, 1900);
  };
  return (
    <div>
      <header className="bg-white">
        <nav
          className="container mx-auto p-3 flex items-center justify-between lg:px-8"
          aria-label="Global"
        >
          <div className="flex text-sm justify-center items-center">
            <a
              href="mailto:cs@medexir.com?subject=Mail from new contact"
              target="_blank"
            >
              <img className="h-8 w-auto" src={Envelope} alt="" />
            </a>
            <span className="text-primary mx-3 hidden md:block">
              cs@medexir.com
            </span>
            <Button className="rounded-lg text-primary bg-white mx-2">
              <a href="https://wa.me/+16307060362" target="_blank">
                <span className="inline-flex ">
                  {__("connect.with.agent")}
                  <img className="h-6 mx-2" src={whatsapp} alt="" />
                </span>
              </a>
            </Button>
            <div className="hidden md:block">
              <Link to="/contact-us" className="text-primary mx-3 p-0.5">
                {__("contact.us")}
              </Link>
              <Link to="/Faq" className="text-primary mx-3 p-0.5">
                {__("faq.header")}
              </Link>
            </div>
          </div>

          <div className="flex gap-6 self-center ">
            <a
              className="hidden md:block"
              href="https://www.facebook.com/profile.php?id=100093053563596&mibextid=LQQJ4d"
              target="_blank"
            >
              <Facebook className="stroke-primary" />
            </a>
            <a
              className="hidden md:block"
              href="https://twitter.com/medexirusa?s=11&t=OKbV6JIru4EIt1pcZ3h2pg"
              target="_blank"
            >
              <Twitter className="stroke-primary" />
            </a>

            <Dropdown
              overlay={renderLanuageMenu()}
              className="rtl:font-kufi mx-2"
            >
              <Button className="bg-primary" type="primary">
                {__(getCurrentLanguage())}
                <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </nav>
      </header>
      <header className="bg-primary">
        <nav
          className="container mx-auto flex items-center justify-between p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="ml-14 ltr:flex-grow">
            <a href="/" className="">
              <span className="sr-only">CLINK</span>
              {logoOnChangelang()}
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
          <Popover.Group className="hidden lg:flex lg:gap-x-12">
            <Link to="/" className="text-sm font-semibold leading-6 text-white">
              {__("home")}
            </Link>
            <Link
              to={"/about-us"}
              className="text-sm font-semibold leading-6 text-white"
            >
              {__("about.us")}
            </Link>
            <Link
              to={"/plans"}
              className="text-sm font-semibold leading-6 text-white"
            >
              {__("services")}
            </Link>
            <Link
              to={"/consultants"}
              className="text-sm font-semibold leading-6 text-white"
            >
              {__("consultation")}
            </Link>
            <Link
              to={"/how-it-works"}
              className="text-sm font-semibold leading-6 text-white"
            >
              {__("how.works")}
            </Link>
            {user && user.id && (
              <Link
                to={"/dashboard"}
                className="text-sm font-semibold leading-6 text-white"
              >
                {__("dashboard")}
              </Link>
            )}
          </Popover.Group>
          {!user.id && (
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <button
                className="rounded-md bg-white text-primary px-4 py-2
                  hover:bg-primary-light hover:text-white duration-300 rtl:mr-4 ltr:mr-4"
              >
                <Link to="/login">{__("login.register")}</Link>
              </button>
            </div>
          )}
          {user && user.id && (
            <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <button
                className="rounded-md bg-white text-primary px-4 py-2
                   hover:bg-primary-light hover:text-white duration-300 rtl:mr-4 ltr:mr-4"
                onClick={onLogout}
              >
                <Link to="/">{__("logout")}</Link>
              </button>
            </div>
          )}
          {!user.id && (
            <div>
              <button
                className="rounded-md bg-white text-primary px-4 py-2
             hover:bg-primary-light hover:text-white duration-300 
              rtl:mr-4"
                onClick={() => setMobileMenuOpen(false)}
              >
                <Link to="/login">{__("request.consult")}</Link>
              </button>
            </div>
          )}
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel
            className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6
          sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
          >
            <div className="flex items-center justify-between bg-primary p-4 m-[-2rem]">
              <a href={{}} className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                {logoOnChangelang()}
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <a
                    href={{}}
                    className="text-sm font-semibold leading-6 text-white"
                  >
                    {__("home")}
                  </a>
                  <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className="flex w-full items-center flex-row-reverse justify-between 
                       rounded-lg py-2 pl-3pr-3.5 text-base font-semibold text-primary leading-7 hover:bg-gray-50"
                        >
                          <Link to="/" onClick={() => setMobileMenuOpen(false)}>
                            {__("home")}
                          </Link>
                        </Disclosure.Button>
                        <Disclosure.Button
                          className="flex w-full items-center flex-row-reverse justify-between
                     rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold text-primary leading-7 hover:bg-gray-50"
                        >
                          <Link
                            to={"/about-us"}
                            onClick={() => setMobileMenuOpen(false)}
                          >
                            {__("about.us")}
                          </Link>
                        </Disclosure.Button>
                        <Disclosure.Button
                          className="flex w-full items-center flex-row-reverse justify-between
                     rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold text-primary leading-7 hover:bg-gray-50"
                        >
                          <Link
                            to={"/plans"}
                            onClick={() => setMobileMenuOpen(false)}
                          >
                            {__("services")}
                          </Link>
                        </Disclosure.Button>
                        <Disclosure.Button
                          className="flex w-full items-center flex-row-reverse justify-between
                     rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold text-primary leading-7 hover:bg-gray-50"
                        >
                          <Link
                            to={"/consultants"}
                            onClick={() => setMobileMenuOpen(false)}
                          >
                            {__("consultation")}
                          </Link>
                        </Disclosure.Button>
                        <Disclosure.Button
                          className="flex w-full items-center flex-row-reverse justify-between
                       rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold text-primary leading-7 hover:bg-gray-50"
                        >
                          <Link
                            to={"/how-it-works"}
                            onClick={() => setMobileMenuOpen(false)}
                          >
                            {__("how.works")}
                          </Link>
                        </Disclosure.Button>
                        {user && user.id && (
                          <Disclosure.Button
                            className="flex w-full items-center flex-row-reverse justify-between
                      rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold text-primary leading-7 hover:bg-gray-50"
                          >
                            <Link
                              to={"/dashboard"}
                              onClick={() => setMobileMenuOpen(false)}
                            >
                              {__("dashboard")}
                            </Link>
                          </Disclosure.Button>
                        )}
                        {!user.id && (
                          <Disclosure.Button
                            className="flex w-full items-center flex-row-reverse justify-between
                      rounded-lg py-2 pl-3pr-3.5 text-base font-semibold text-primary leading-7 hover:bg-gray-50"
                          >
                            <Link
                              to="/login"
                              onClick={() => setMobileMenuOpen(false)}
                            >
                              {__("login.register")}
                            </Link>
                          </Disclosure.Button>
                        )}
                        {user && user.id && (
                          <Disclosure.Button
                            className="flex w-full items-center flex-row-reverse justify-between
                       rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold text-primary leading-7 hover:bg-gray-50"
                            onClick={onLogout}
                          >
                            {__("logout")}
                          </Disclosure.Button>
                        )}
                      </>
                    )}
                  </Disclosure>
                </div>
                <div className="py-6">
                  <a
                    href={{}}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-50"
                  >
                    {__("login")}
                  </a>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </div>
  );
};

export default Header;
