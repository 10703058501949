import { Result } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTrashCan } from '@fortawesome/free-solid-svg-icons';

import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow
} from '../../../shadcn/table';
import __ from '../../../lang';
import Dialog from '../../../shadcn/dialog';
import Card from '../../../components/card';
import { Badge } from '../../../shadcn/badge';
import { Button } from '../../../shadcn/button';
import actionsFactory from '../../../actions/main-actions';
import DashboardNav from '../../../components/dashboard-nav';
import * as utilsActions from '../../../actions/utils-actions';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../shadcn/tabs';

const Index = ({ files }) => {
    const [showModal, setShowModal] = useState(false);
    const [showModalfirst, setShowModalfirst] = useState(true);
    const actions = actionsFactory('meetings/consultation');
    const actionsFiles = actionsFactory('meetings/consultation-files');
    const { id } = useParams();
    const { selected } = useSelector((state) => state.consultations);
    const { list } = useSelector((state) => state.consultationsFiles);
    const fileInputRef = useRef(null);

    const statusMap = {
        0: 'Under review by case manager',
        1: 'Under review by consultant',
        2: 'Info is missing',
        3: 'Case manager contacted patient',
        4: 'Completed'
    };

    useEffect(() => {
        actions.getOne(id, 'expand=patient,specialty/');
        actionsFiles.searchAction(`?consultation=${id}`);
    }, []);

    const onUpload = (item) => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('consultation', id);
        utilsActions.uploadConsultationFile(formData, () => {
            actionsFiles.searchAction(`?consultation=${id}`);
        });
    };

    const onDelete = (item) => {
        actionsFiles.deleteAction(item);
    };

    const extractFileName = (url) => {
        const lastSlashIndex = url.lastIndexOf('/');
        const fileNameWithParams = url.substring(lastSlashIndex + 1);
        const fileName = fileNameWithParams.split('?')[0];

        return fileName;
    };

    return (
        <div className="grow container mx-auto items-center gap-8 md:gap-32 px-4 py-8 h-fit">
            <div className="flex flex-col gap-4">
                <DashboardNav active="consultations" />
                <Card className="items-start gap-8 grow" noHover={true}>
                    <Tabs
                        defaultValue={files ? 'files' : 'details'}
                        className="w-full"
                    >
                        <TabsList className="flex flex-row justify-start">
                            <TabsTrigger value="details">
                                {__('consultation.details')}
                            </TabsTrigger>
                            <TabsTrigger value="files" className="gap-2">
                                <FontAwesomeIcon icon={faUpload} />
                                {__('files')}
                            </TabsTrigger>
                        </TabsList>
                        <TabsContent
                            value="details"
                            className="my-[-1px] gap-4 flex flex-col"
                        >
                            <div className=" grow p-4 bg-slate-50 border-0 shadow-none">
                                <div className="flex flex-col md:flex-row lg:flex-row items-start gap-2">
                                    <div className="grow">
                                        <div className="font-bold flex-col md:flex-row lg:flex-row gap-4 text-lg">
                                            {__('case.title')}
                                            <Badge variant="outline">
                                                {statusMap[selected.status]}
                                            </Badge>
                                        </div>
                                        <p className="text-lg mt-2">
                                            {selected.title}
                                        </p>
                                    </div>
                                    {selected.patient && (
                                        <Badge className="text-white">
                                            {__('patient')}:{' '}
                                            {selected.patient.first_name}
                                            {selected.patient.last_name}
                                        </Badge>
                                    )}
                                    {selected.specialty && (
                                        <Badge className=" bg-orange-200 hover:bg-orange-900 hover:text-white">
                                            {__('specialty')}:{' '}
                                            {selected.specialty.slug}
                                        </Badge>
                                    )}
                                </div>
                            </div>
                            <div className="rounded grow p-4 bg-slate-50 border-0 shadow-none">
                                <div className="font-bold">
                                    {__('consultations.description')}
                                </div>
                                {selected.issue}
                            </div>
                            <div className="rounded grow p-4 bg-slate-50 border-0 shadow-none">
                                <div className="font-bold">
                                    {__('questions')}
                                </div>
                                {selected.questions}
                            </div>
                        </TabsContent>
                        <TabsContent
                            value="files"
                            className="p-4 flex flex-col gap-4"
                        >
                            <div className="actions flex flex-col lg:flex-row  w-full items-end justify-end">
                                <Button
                                    onClick={() => setShowModal(true)}
                                    className="text-white gap-4 text-md bg-green-500 hover:bg-green-400"
                                >
                                    <FontAwesomeIcon icon={faUpload} />
                                    ارسال الاستشارة
                                </Button>
                                <Button
                                    onClick={onUpload}
                                    className="text-white gap-4 text-md  ml-2 mt-2"
                                >
                                    <FontAwesomeIcon icon={faUpload} />
                                    {__('upload.file')}
                                </Button>
                            </div>
                            <Table>
                                <TableCaption>
                                    {__('list.recent.files')}
                                </TableCaption>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead>{__('file')}</TableHead>
                                        <TableHead>{__('category')}</TableHead>
                                        <TableHead className="text-right">
                                            {__('date.created')}
                                        </TableHead>
                                        <TableHead className="text-right">
                                            {__('action')}
                                        </TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {list.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell className="font-medium">
                                                <Button variant="link" asChild>
                                                    <a
                                                        target="_blank"
                                                        href={item.file}
                                                        rel="noreferrer"
                                                    >
                                                        {extractFileName(
                                                            item.file
                                                        )}
                                                    </a>
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                {item.category}
                                            </TableCell>
                                            <TableCell className="text-right">
                                                {item.created_at.split('T')[0]}
                                            </TableCell>
                                            <TableCell className="text-right">
                                                <div className="flex flex-row gap-2 justify-end">
                                                    <Button
                                                        onClick={() =>
                                                            onDelete(item)
                                                        }
                                                        className="gap-2"
                                                        variant="destructive"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faTrashCan}
                                                        />
                                                        {__('delete')}
                                                    </Button>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <input
                                onChange={handleFileChange}
                                ref={fileInputRef}
                                type="file"
                                className="hidden"
                            />
                        </TabsContent>
                    </Tabs>
                </Card>
                {showModal === true && (
                    <Dialog visible={showModal} setVisible={setShowModal}>
                        <Result
                            status="success"
                            title={__('uploadfiles.modal.title2')}
                        />
                    </Dialog>
                )}
                {showModal === true && (
                    <Dialog
                        visible={showModalfirst}
                        setVisible={setShowModalfirst}
                    >
                        <Result title={__('uploadfiles.modal.title3')} />
                    </Dialog>
                )}
            </div>
        </div>
    );
};

export default Index;
