import { useState } from 'react';
import {
    BsFillArrowRightCircleFill,
    BsFillArrowLeftCircleFill
} from 'react-icons/bs';
import { getCurrentLanguage } from '../../lang';

export default function Carousel({ slides }) {
    const [current, setCurrent] = useState(0);

    const selectedLang = getCurrentLanguage();

    const previousSlide = () => {
        if (current === 0) setCurrent(slides.length - 1);
        else setCurrent(current - 1);
    };

    const nextSlide = () => {
        if (current === slides.length - 1) setCurrent(0);
        else setCurrent(current + 1);
    };

    return (
        <div
            className="border-2 border-solid border-green-700 rtl:mr-12 ltr:ml-12
                        overflow-hidden relative w-[78%] mt-[70px] row-start-2
                        sm:ml-[120px] sm:w-[79%] sm:row-start-1 sm:row-end-1 sm:mt-1
                        md:ml-[120px] md:w-[79%] md:row-start-1 md:row-end-1 md:mt-1
                        lg:ml-[165px] lg:w-[78%] lg:row-start-1 lg:row-end-1 lg:mt-1"
            key={current}
        >
            <div className={`flex transition ease-out duration-40`}>
                <img src={slides[current]} alt="" />;
            </div>
            <div className="absolute top-0 h-full w-full justify-between items-center flex text-white px-10 text-3xl">
                <div
                    className={
                        selectedLang === 'ar' ? 'flex flex-row-reverse' : ''
                    }
                >
                    <button onClick={previousSlide}>
                        <BsFillArrowLeftCircleFill />
                    </button>
                    <button onClick={nextSlide}>
                        <BsFillArrowRightCircleFill />
                    </button>
                </div>

                <div className="absolute bottom-0 py-4 flex justify-center gap-3 w-full">
                    {slides.map((s, i) => {
                        return (
                            <div
                                onClick={() => {
                                    setCurrent(i);
                                }}
                                key={'circle' + i}
                                className={`rounded-full w-5 h-5 cursor-pointer  ${
                                    i === current ? 'bg-white' : 'bg-gray-500'
                                }`}
                            ></div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
