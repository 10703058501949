import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Routing from './routes';
import { getCurrentLanguage } from './lang';

import './App.css';

const App = () => {
    return (
        <div
            className={`App bg-slate-100 font-${getCurrentLanguage()} app-${getCurrentLanguage()} min-h-screen flex flex-col`}
            dir={getCurrentLanguage() === 'ar' ? 'rtl' : 'ltr'}
        >
            <Router>
                <Routing />
            </Router>
        </div>
    );
};

export default App;
