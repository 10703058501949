import React from "react";
import __ from "../../lang";
import step1Image from "../../assets/icon/howitwork/1.png";
import step2Image from "../../assets/icon/howitwork/2.png";
import step3Image from "../../assets/icon/howitwork/3.png";
import step4Image from "../../assets/icon/howitwork/5.png";
import step6Image from "../../assets/icon/howitwork/6.png";
import step7Image from "../../assets/icon/howitwork/7.png";
import step5Image from "../../assets/icon/howitwork/9.png";
import step8Image from "../../assets/icon/howitwork/4.png";
import step9Image from "../../assets/icon/howitwork/8.png";

const HowWorks = () => {
  return (
    <div className="bg-auto bg-no-repeat bg-center">
      <div className="container p-4">
        <div className="flex flex-col md:h-[500px] mt-8 md:flex-row md:odd:flex-row-reverse auto-cols-max items-center">
          <div className="md:w-1/2">
            <img className="" src={step1Image} alt="" />
          </div>
          <div className="md:w-4"></div>
          <div className="md:w-1/3 flex-col flex">
            <p className="text-gray-500 text-lg">
              <span className="text-blue-600 font-medium">{__("step1")}</span>
              {__("step.1")}
            </p>
          </div>
        </div>
        <div className="flex flex-col md:h-[450px] md:flex-row md:odd:flex-row-reverse auto-cols-max items-center">
          <div className="md:w-1/2 ">
            <img src={step2Image} className="" alt="" />
          </div>
          <div className="md:w-4"></div>
          <div className="md:w-1/3 flex-col flex">
            <p className="text-gray-500 text-lg">
              <span className="text-blue-600 font-medium ">{__("step2")}</span>
              {__("step.2")}
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:h-[450px] md:odd:flex-row-reverse auto-cols-max items-center">
          <div className="md:w-1/2">
            <img src={step3Image} className="" alt="" />
          </div>
          <div className="md:w-4"></div>
          <div className="md:w-1/3 flex-col flex">
            <p className="text-gray-500 text-lg ">
              <span className="text-blue-600 font-medium">{__("step3")}</span>
              {__("step.3")}
            </p>
          </div>
        </div>
        <div className="flex  flex-col md:flex-row md:h-[450px] md:odd:flex-row-reverse items-center auto-cols-max">
          <div className="md:w-1/2">
            <img src={step4Image} className="" alt="" />
          </div>
          <div className="md:w-4"></div>
          <div className="md:w-1/3 flex-col flex">
            <p className="text-gray-500 text-lg">
              <span className="text-blue-600 font-medium">{__("step4")}</span>
              {__("step.4")}
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:h-[450px] md:odd:flex-row-reverse items-center auto-cols-max">
          <div className="md:w-1/2">
            <img
              src={step5Image}
              className="h-[500px] md:mr-6 md:ltr:ml-6"
              alt=""
            />
          </div>
          <div className="md:w-4"></div>
          <div className="md:w-1/3 flex-col flex">
            <p className="text-gray-500 text-lg">
              <span className="text-blue-600 font-medium">{__("step5")}</span>
              {__("step.5")}
            </p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:h-[450px] md:odd:flex-row-reverse items-center auto-cols-max">
          <div className="md:w-1/2">
            <img src={step7Image} className="" alt="" />
          </div>
          <div className="md:w-4"></div>
          <div className="md:w-1/3 flex-col flex">
            <p className="text-gray-500 text-lg">
              <span className="text-blue-600 font-medium">{__("step6")}</span>
              {__("step.6")}
            </p>
          </div>
        </div>
        <div className="flex  flex-col md:flex-row md:h-[450px] md:odd:flex-row-reverse items-center auto-cols-max">
          <div className="md:w-1/2">
            <img src={step6Image} className="" alt="" />
          </div>
          <div className="md:w-4"></div>
          <div className="md:w-1/3 flex-col flex">
            <p className="text-gray-500 text-lg">
              <span className="text-blue-600 font-medium">{__("step7")}</span>
              {__("step.7")}
            </p>
          </div>
        </div>
        <div className="flex  flex-col md:flex-row md:h-[450px] md:odd:flex-row-reverse items-center auto-cols-max">
          <div className="md:w-1/2">
            <img src={step9Image} className="" alt="" />
          </div>
          <div className="md:w-4"></div>
          <div className="md:w-1/3 flex-col flex">
            <p className="text-gray-500 text-lg">
              <span className="text-blue-600 font-medium">{__("step8")}</span>
              {__("step.8")}
            </p>
          </div>
        </div>
        <div className="flex  flex-col md:flex-row md:h-[450px] md:odd:flex-row-reverse items-center auto-cols-max">
          <div className="md:w-1/2">
            <img src={step8Image} className="" alt="" />
          </div>
          <div className="md:w-4"></div>
          <div className="md:w-1/3 flex-col flex">
            <p className="text-gray-500 text-lg">
              <span className="text-blue-600 font-medium">{__("step9")}</span>
              {__("step.9")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWorks;
