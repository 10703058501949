import Hero from '../../components/hero';
import Statistics from '../../components/statistics';
import Carousel from '../../components/carousel/index';
import About from '../../components/about';

const Home = () => {
    return (
        <div className="flex flex-col gap-16">
            <Hero />
            <Statistics />
            <Carousel />
            <About />
        </div>
    );
};

export default Home;
