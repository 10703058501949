import { UseAction } from "./base-actions";
import reducer from "../reducers/main";

const actionsFactory = (entity) => {
  const actions = reducer(entity).reducerActions;
  return {
    getAll: (cb) =>
      UseAction({
        url: `v1/${entity}/`,
        method: "get",
        action: actions.setAll,
        cb,
      }),
    setAll: (payload, cb) =>
      UseAction({
        normalAction: true,
        action: actions.setAll,
        payload: payload,
        cb,
      }),
    deleteAction: (reqPayload, cb) =>
      UseAction({
        url: `v1/${entity}/${reqPayload.id}/`,
        method: "delete",
        action: actions.delete,
        payload: reqPayload.id,
        cb,
      }),
    setMetaAction: (payload, cb) =>
      UseAction({
        normalAction: true,
        action: actions.setMeta,
        payload: payload,
        cb,
      }),
    searchAction: (search, cb) =>
      UseAction({
        url: `v1/${entity}/${search || ""}`,
        method: "get",
        action: actions.setAll,
        cb,
      }),
    updateAction: (reqPayload, extra, cb) =>
      UseAction({
        action: actions.edit,
        reqPayload,
        url: `v1/${entity}/${reqPayload.id}/${extra ? extra : ""}`,
        method: "put",
        cb,
      }),
    addAction: (reqPayload, cb) =>
      UseAction({
        action: actions.add,
        reqPayload,
        url: `v1/${entity}/`,
        method: "post",
        cb,
      }),
    editAction: (reqPayload, cb) =>
      UseAction({
        action: actions.edit,
        reqPayload,
        url: `v1/${entity}/${reqPayload.id}/`,
        method: "patch",
        cb,
      }),
    selectAction: (id, cb) =>
      UseAction({
        normalAction: true,
        action: actions.select,
        payload: parseInt(id),
      }),
    getOne: (id, search, cb) =>
      UseAction({
        url: `v1/${entity}/${id}/${search ? `?${search}` : ""}`,
        method: "get",
        action: actions.setSelected,
        cb,
      }),
  };
};

export default actionsFactory;
