const lang = {
    en: 'English',
    ar: 'عربي',

    // login register
    clink: 'Clink',
    'welcome.back': 'WELCOME BACK',
    'no.account': 'No Account?',
    register: 'Register',
    'username.password.invalid': 'Username or Password invalid',
    'please.enter.username': 'Please enter your Username',
    'please.enter.email': 'Please enter your Email',
    'enter.username': 'Enter Username',
    'please.enter.password': 'Please enter your Password',
    'enter.password': 'Enter Password',
    'enter.email': 'Enter Your Email',
    'forget.password': 'Forgot password ?',
    login: 'Log In',
    'sign.in.google': 'Sign In by Google',
    'remember.me': 'Remember me',
    submit: 'Submit',
    email: 'E-mail',
    'not.valid.email': 'The input is not valid E-mail!',
    'input.email': 'Please input your E-mail!',
    password: 'Password',
    'validation.password': 'Must contain at least 8 characters.',
    'confirm.password': 'Confirm Password',
    'please.confirm.password': 'Please confirm your password!',
    'password.not.match': 'The two passwords that you entered do not match!',
    'password.enter': 'Please enter your email to reset your password!',
    phone: 'Phone',
    'input.phone': 'Please input your phone number!',
    type: 'Name',
    'input.type': 'Please input your type',
    patient: 'Patient',
    doctor: 'Doctor',
    me: 'Me',
    other: 'Other',
    'accept.policy': 'I accept all the terms of use and the privacy policy',
    'registration.successful': 'Your Registration was Successful!',
    'registration.successful.2':
        'Great,Your Registration has been Submitted Successfully',
    'registration.failed': 'Your Registration Failed!',
    'registration.failed.2': 'Oops,Your Registration has not succeeded!',
    'go.profile': 'Go to Profile',
    close: 'Close',
    continue: 'Continue',
    back: 'Back',
    'accept.agreement': 'Should accept agreement',
    'registration.succeed': 'Registration succeed',
    'email.exists': 'user with this email already exists',
    'upload.profile.picture': 'Upload Your Profile Picture',
    'how.hear': 'How did you hear about us ?',
    'google.search': 'Google search',
    'by.friend': 'Refered by a Friend',
    'by.doctor': 'Refered by a Doctor',
    twiter: 'Twiter',
    instagram: 'Instagram',
    snapchat: 'Snapchat',
    facebook: 'Facebook',
    whatsApp: 'WhatsApp',

    //request call
    'next.step': 'What do you want to do next?',
    'request.call': 'Connect with our agent',
    'enter.manually': 'Enter Info Manually',

    // Demographics Patient
    demographics: 'Demographics',
    'details.data': 'Details Data',
    'thank.you': 'Thank You',
    'first.name': 'First Name',
    'middle.name': 'Middle Name',
    'last.name': 'Last Name',
    address_1: 'Address line 1',
    upload: 'Upload',
    'upload.size': 'Image must smaller than 2MB',
    'upload.type': 'You can only upload JPG/PNG file',
    'address.2': 'Address line 2',
    city: 'City',
    'profile.picture': 'Profile Picture',
    country: 'Country',
    zip: 'Zip/Postal Code',
    state: 'State/Province',
    'date.birth': 'Date Of Birth',
    male: 'Male',
    female: 'Female',
    gender: 'Gender',
    'first.name.required': 'First Name is Required',
    'last.name.required': 'Last Name is Required',
    'address.1.required': 'Address line 1 is Required',
    'city.required': 'City is Required',
    'country.required': 'Country is Required',
    'zip.required': 'Zip is Required',
    'dob.required': 'Dob is Required',
    'gender.required': 'Gender is Required',
    'first.name.string': 'First name must be a string',
    'last.name.string': 'Last name name must be a string',
    'gender.name.string': 'Gender name must be a string',

    // Speciaility for Patient
    'specialty.account': 'specialty Account',
    speciaility: 'Speciaility',
    'select.speciaility': 'Select Speciaility',
    'choose.the.best': 'We will choose the best doctors for you',
    'preferred.consultant': 'Preferred Consultant',
    'select.consultant': 'Select consultant',

    //servicesCase
    'our.service': 'Our Services',
    'first.package': 'First package',
    'second.package': 'Second package',
    'third.package': 'Third Package',
    'select.services':
        'Choose the package that suits your needs and preferences',
    case1: 'Creating a medical file for each patient',
    case2: 'Receiving all reports, tests, and inquiries sent.',
    case3: 'Assigning a dedicated coordinator physician to ensure the completion of medical information related to the case.',
    case4: 'Appointing a consultant from the United States to follow up on the case.',
    case5: 'Reviewing the case and accompanying medical files by the consultant.',
    case6: 'Providing the patient with a detailed report on the second medical opinion.',
    case7: 'Developing a treatment plan for the patient in line with American medical standards.',
    case8: 'Addressing all patient and treatment team inquiries.',
    case9: 'Free medical case follow-up after obtaining the second medical opinion (via messaging).',
    'case9.des1': 'Within one week of obtaining the consultation.',
    'case9.des2': 'Within two weeks of obtaining the consultation.',
    'case9.des3': 'Within three months of obtaining the consultation.',
    case10: 'Discount on future consultations.',
    case11: 'Communicating with the overseeing consultant through a video call.',
    'case11.des2': 'One video call for 20 minutes',
    'case11.des3':
        '6 video calls for 15 minutes each, with the consultant every two weeks.',
    case12: 'Reviewing images and radiological files related to the case by a radiology consultant in the United States',
    case13: 'Obtaining a new report after reviewing the case by the radiology consultant',
    'choose.service': 'Choose Service',
    //Patient Case Page1
    'title.case': 'Title Of Case',
    'name.patient': 'Name of Patient',
    allergies: 'Allergies',
    'past.medical.history': 'Past Medical History',
    description: 'Medical description',
    'past.surgical.history': 'Past Surgical History',
    'questions.consultant': 'Questions to the consultant',
    'title.case.required': 'Title Of Case is required',
    'name.patien.required': 'Name Of Patient is required',

    //Patient Case Page2
    'when.problem.start': 'When did the problem start ?',
    'what.test.done': 'What test has been done ?',
    'what.symptoms.bothering':
        'What symptoms are bothering patient the most now ?',
    'what.treatment.received': 'What treatment has been received ?',
    'What.treatment.patient.now': 'What treatment is the patient on now ?',

    //Menu
    home: 'Home',
    'about.us': 'About Us',
    'for.patients': 'For Patients',
    'for.employers': 'For Employers',
    'for.physicians': 'For Physicians',
    consultants: 'Consultants',
    services: 'Services',
    logout: 'Log out',
    'login.register': 'Login/Register',
    'request.consult': 'Request Consult',
    'contact.us': 'Contact Us',
    faq: 'FAQ',
    'how.works': 'How to Get Consultation',
    pricing: 'Pricing',
    testimonials: 'Testimonials',
    consultation: 'Consultants',

    //Main
    welcome1: 'WELCOME TO CLINK',
    welcome2: 'Your Gateway to Caring Healthcare',
    welcome3: 'Virtual Consultations from American-trained Consultants',
    welcome4:
        'We understand the importance of making informed healthcare decisions, and our team of American-trained doctors is here to guide you every step of the way.',
    welcome5:
        'With a seamless experience, affordable prices, and the highest level of medical accuracy, we are committed to revolutionizing healthcare access for you. Welcome to Clink, where your well-being is our top priority.',
    welcome6:
        "At Clink, we go beyond just being a healthcare platform; we're your partners in well-being. Embrace a new era of personalized care, where your health takes center stage. Our dedicated team of doctors is committed to providing caring telemedicine services and second opinions, ensuring that you have access to top-tier healthcare from the comfort of your home. Join Clink today and experience a revolutionary approach to healthcare - where expertise meets genuine concern. Your health journey starts here.",
    'consult.now': 'Request a Consult Now',
    'your.email': 'Your Email Address',
    'b.doctor': 'Best Doctor',
    'name.doctor': 'Dr. Shadi Latta ',
    'ceo.doctor': 'CEO, clink',
    'type.doctor': 'Consultant Hematlogy-Oncology',

    // FAQ
    'frequently.question': 'Frequently Asked Question',
    'q.first.opinion': 'What is the second opinion?',
    'a.first.opinion':
        'A second medical opinion refers to an evaluation or consultation obtained from another physician after aninitial consultation. A second medical opinion is typically sought when a patient needs additional assessment or another medical opinion regarding their diagnosis or proposed treatment plan. It enhancesthe patient diagnosis provides additional insights, and offers important guidance. It helps the patientmake informed healthcare decisions and improves overall healthcare management.',
    'q.second.opinion': 'How important is a second opinion?',
    'a.second.opinion.a':
        'Ensuring the correct diagnosis: A second medical opinion can help confirm the proposed diagnosis andensure its accuracy. Diagnostic errors can occur at times, and obtaining multiple opinions from different physicians can increase confidence in the provided diagnosis.',
    'a.second.opinion.b':
        'Evaluating treatment options: The second opinion can assist in evaluating available treatment options and provide fresh insights and crucial guidance. The second physician may present different treatment choices or offer additional information regarding the benefits and risks of the proposed treatment.',
    'a.second.opinion.c':
        'Providing peace of mind: The second medical consultation can provide psychological reassurance to the patient, alleviating anxiety and doubts that may exist regarding the diagnosis or treatment. The second opinion can enhance the patient confidence and comfort, helping them make more informed decisions about their healthcare.',
    'a.second.opinion.d':
        'Improving health outcomes: The second medical opinion can potentially improve the patient health outcomes. By obtaining an additional opinion from a specialized physician, the best practices and measures for the patient care can be identified, leading to better health outcomes.',
    'q.third.opinion': 'How long does a second opinion take?',
    'a.third.opinion':
        'We do our best to provide a second opinion in a timely manner, most patients receive their second opinion within 4-7 working days, depending on the complexity of the case and the availability of all medical records relevant to your case.',
    'q.fourth.opinion': 'How much does a second opinion cost?',
    'a.fourth.opinion':
        'At the forefront of our agenda is your health, as we strive to deliver top-notch healthcare advice to all individuals at reasonable costs. You can access information regarding clink services and pricing from here.',
    'q.fifth.opinion': 'Does insurance cover the costs of a second opinion?',
    'a.fifth.opinion':
        'Unfortunately, most health insurances do not cover the costs of a second opinion . The patient is responsible for these costs when seeking a consultation.',
    'q.sixth.opinion':
        'What language of medical records can you provide us with?',
    'a.sixth.opinion':
        'When you request the second opinion service, we ask you to upload medical files and records related to your health condition. These files can be in Arabic or English.',
    'q.seventh.opinion': 'Who chooses the doctor providing my second opinion? ',
    'a.seventh.opinion':
        'After reviewing your request, our team will connect you with the appropriate doctor for your case, and you can also request a specific doctor through the list of doctors on our website.',
    'q.eighth.opinion':
        'Do the doctors at clink have the necessary qualifications to provide a second medical opinion?',
    'a.eighth.opinion':
        'Yes, our team consists of top-notch doctors who have received their training in the United States of America. They possess high competence and extensive experience in their medical specialties, and they adhere to the highest standards of medical precision and professionalism',
    'q.ninth.opinion': 'How does clink work?',
    'a.ninth.opinion':
        'clink provides easy-to-access services for obtaining a second medical opinion. Simply submit your medical records and relevant information, consult with a certified specialist, receive a detailed report, and benefit from ongoing support.',

    //Statistics
    statistics: 'Statistics',
    'set.statistics': 'A set of reliable statistics',
    'statistics.one':
        '50% of diagnosis errors can be avoided with second opinion',
    'statistics.one.p':
        'In a new study, Mayo Clinic reports that as many as 88% of those patients go home with a new or refined diagnosis ',
    'statistics.two':
        '50% of patients avoid unnecessary surgery after obtaining a second opinion',
    'statistics.two.p':
        'We estimate that among 130 million emergency department (ED) visits per year in the United States that 7.4 million (5.7%) patients are misdiagnosed',
    'statistics.three': '88% of diagnosis corrections after second opinions',
    'statistics.three.p':
        '67% change their treatment plans after seeking second opinion',

    'verify.phone': 'Verify Your Phone Number',
    'please.verify.your.phone': 'Please Verify Your Phone Number',
    'verify.phone.required': 'Verify Phone Number Required',

    'consultation.info': 'Consultation Info',
    'who.is.this.for': 'Who is This For',
    'speciality.account': 'Speciality Account',
    //About Us
    'care.for':
        'We Care For supporting patients and dealing with challenging cases',
    'p.about.us':
        'We are a network of the best consultants in The United States of America who are fluent in Arabic and from all medical specialties and subspecialities. We strive to provide medical consultations and high-quality health care. Our team works in the most prestigious American hospitals and has great experience in supporting patients and dealing with challenging cases in accordance with international medical standards.',
    'read.more': 'Read More',
    'years.experience': 'Years of Experience',

    'welcome.clink':
        'Welcome to Clink: Your Trusted Second Opinion Platform for MENA Arabic-Speaking Patients',
    'power.caption':
        'Unlock the Power of Knowledge: Discover clinks Revolutionary Services',
    'tag.line': 'Unlocking Medical Clarity, One Opinion at a Time.',
    //Our vision
    'our.vision': 'Our vision',
    'our.vision.text':
        'We strive to be the first We strive to be the leading integrated platform providing medical consultations from American consultants. We work diligently to deliver healthcare to patients anytime, anywhere, through a diverse group of consultant physicians in various medical fields.',
    //Mission Statement
    'mission.statement': 'Mission Statement',
    'mission.statement.text':
        'To provide MENA Arabic-speaking patients with expert second opinion medical consultations by American-trained doctors, delivering a seamless experience, great prices, and the highest medical accuracy.',
    //Vision Statement
    'vision.statement': 'Vision Statement',
    'our.vision.statement':
        'Providing patients with medical consultations from our consultants in the United States, and delivering a comprehensive service with the highest international medical standards at affordable prices.',

    //HOW WORKS

    'seeking.mena':
        'Seeking a Second Opinion Made Easy for MENA Arabic-Speaking Patients ...',
    step1: ' 1- Registration and Creating a New Account : ',
    'step.1':
        'Visit our online platform and create a new account. Enter basic information such as your name, email, and phone number.',
    step2: ' 2- Adding a Patient : ',
    'step.2':
        'After successful login, go to the patient section and add information about the new patient. You can add multiple patients to the same account.',
    step3: '3- Requesting a New Consultation : ',
    'step.3':
        'Navigate to the consultation section where you can request a medical consultation. Choose the patient for whom you want to request the consultation.',
    step4: '4- Selecting the service and Completing the Payment : ',
    'step.4':
        'After clicking on "Add New Consultation," select the service and complete the payment process. Then, provide medical information about the condition and upload medical reports.',
    step5: '5- Reviewing the Medical Consultation : ',
    'step.5':
        'Once your consultation is successfully registered, a dedicated coordinator will be assigned to you. The coordinator will contact you to ensure all information and medical records are complete. Then, they will transfer the case to the appropriate consulting physician.',
    step6: '6- Medical Consultation Report : ',
    'step.6':
        'The consulting physician will carefully review your case and medical reports. They will then write a detailed report containing the medical consultation based on the latest American medical standards.',
    step7: '7- Accessing Consultation Information : ',
    'step.7':
        'After the medical report is completed, it will be added to your account on our website, and a copy of the report will be sent to you via email.',
    step8: '8- If you choose the option of communicating with the consultant through a video call,',
    'step.8':
        ' a convenient appointment will be scheduled for you to connect with the consultant, discuss your case, and address all your inquiries.',
    step9: '9- Follow-up on the Consultation and Inquiries : ',
    'step.9':
        'If you have any questions or inquiries regarding your request or if you want to schedule a second medical consultation, the clink team is always ready to assist you.',

    // Why us
    'why.us': 'Why us?',
    'why.text':
        'We provide medical consultations under the supervision of the best doctors around the world. You can request health care at any time and without the need to travel. Our team will review all aspects of the case and then prepare a booklet regarding the case, which includes details of the medical consultation and recommendations for treatment plan, and this provides peace of mind for you and your family.',
    'why.texttwo':
        'Health care at any time and without travel. Our team will review all aspects of the case and then prepare',
    'why.textthree':
        'booklet regarding the second opinion, which includes details of the medical consultation and recommendations for treatment and prevention, Which provides peace of mind for you and your family',

    // faq:
    'faq.header': 'FAQ’s',
    'faq.1':
        'What is clink? clink is a trusted second opinion platform that connects Arabic-speaking patients in the MENA region with American-trained doctors, offering expert medical consultations.',
    'faq.5':
        'Why should I seek a second opinion? Seeking a second opinion provides you with additional medical insights and perspectives, empowering you to make informed decisions about your healthcare and treatment options.',
    'faq.2':
        'How does clink work? clink provides a seamless process for obtaining a second opinion. Simply submit your medical records and relevant information, consult with a board-certified consultant, receive a detailed report, and benefit from ongoing support.',
    'faq.4':
        'Are the doctors at clink qualified? Yes, our team consists of American-trained doctors who are highly qualified and experienced in their respective medical fields. They adhere to the highest standards of medical accuracy and professionalism.',
    'faq.3':
        'How long does it take to receive a second opinion? The timeframe may vary depending on the complexity of your case. However, we strive to provide timely responses and aim to deliver second opinions within a reasonable timeframe.',

    //consultants menu:
    specialities: 'Specialities',
    'consultants.profiles': 'Consultants Profiles',

    // services menu:
    individuales: 'For Individuales',
    'clinics.hospitals': 'For Clinics and Hospitals',
    employers: 'For Employers',

    // articles menu:
    articles: 'Articles',
    oncology: 'Oncology',
    cardiology: 'Cardiology',
    endocrinology: 'Endocrinology',
    rheumatology: 'Rheumatology',
    Pulmonology: 'Pulmonology',
    orthopedics: 'Orthopedics',
    Pediatrics: 'Pediatric',
    'obstetrics.gynecology': 'Obstetrics and Gynecology',
    'infectious.diseases': 'Infectious diseases',

    //Carousel
    'title.one': 'The best in the US, trained at schools including',
    'title.two': 'Our Consultants are members at leading US Societies',

    // Footer
    request: 'Request',
    now: 'Now',
    code: 'Request Code',
    rights: 'clink. All rights reserved.',
    'terms.policy': 'Terms · Privacy Policy',
    products: 'PRODUCTS',
    resources: 'RESOURCES',
    company: 'COMPANY',
    support: 'SUPPORT',
    'drag.drop': 'Drag And Drop',
    visual: 'Visual Studio X',
    'easy.content': 'Easy Content',
    'industries.tools': 'Industries and tools',
    cases: 'Use cases',
    blog: 'Blog',
    'online.event': 'Online evenet',
    exercitation: 'Nostrud exercitation',
    'diversity.inclusion': 'Diversity & inclusion',
    'about.us.f': 'About us',
    press: 'Press',
    'customer.stories': 'Customer Stories',
    'online.communities': 'Online communities',
    documentation: 'Documentation',
    'tutorials.guides': 'Tutorials & guides',
    webinars: 'Webinars',
    'open.source': 'Open-source',

    // payment
    'payment.info': 'Secure Payment Info',
    'name.card': 'Name on card',
    'number.card': 'Card number',
    'exp.card': 'Expiration date',
    'security.code': 'Security code',
    'pay.now': 'PAY NOW',

    //AboutUsRequirement
    'requirement.account': 'Requirement Account',
    specific: 'Doctors specific requirements/questionnaire',
    'add.item': 'Add item',
    'enter.item': 'Please enter item',
    'measuring.blood': 'Measuring blood pressure',
    neurological: 'Neurological Examination',
    psychiatric: 'Psychiatric Assessment',
    'digital.rectal': 'Digital rectal Examination',

    //our story
    'our.story': 'Our Story',
    'our.story1':
        'At clink, we are passionate about transforming healthcare through the power of second opinions. We understand the challenges faced by MENA Arabic-speaking patients when making crucial medical decisions. Our platform bridges this gap by providing a reliable source of accurate second opinions.',
    'our.story2':
        'Our esteemed team of American-trained doctors brings their expertise to offer comprehensive and trusted second opinions. We are committed to empowering patients with the knowledge necessary to make informed decisions about their health.',
    'our.story3':
        'Built on trust, compassion, and integrity, clink creates a supportive environment for patients seeking second opinions. We personalize the experience, ensuring that every patient receives clarity, understanding, and diverse medical perspectives.',
    'our.story4':
        'Join us on this transformative journey where medical expertise meets compassionate care. clink is your guiding light, empowering you to make confident and informed healthcare decisions. Your well-being is our utmost priority.',

    // Header Side
    'connects.patients': 'Connects Patients From all Over the World',
    'e.sign': 'E-sign Act Disclosure and Consent',
    health: 'E-sign Act Disclosure and Consent Welcome to Healthy ! This podcast is focused on — you guessed it — health ',

    //Cities
    jeddah: 'Jeddah',
    'mekkah ': 'Mekkah ',
    dubai: 'Dubai',
    'abu.dhabi': 'Abu Dhabi',
    cairo: 'Cairo',

    //dr. shadi
    'dr.name': 'Dr. Shadi Latta',
    ceo: 'CEO, clink',
    consultan: 'Consultant Hematlogy-Oncology',

    //dashboard
    'add.patient': 'Add a new patient',
    'list.recent.patients': 'A list of your recent patients',
    'pick.date': 'Pick a date',
    delete: 'Delete',
    'delete.patient': 'Delete Patient',
    'delete.consultation': 'Delete Consultation',
    avatar: 'Avatar',
    patients: 'Patients',
    consultations: 'Consultations',
    profile: 'Profile',
    'first.name.is.required': 'First name is required',
    'submit.patient': 'Adding a Patient',

    //dashboard consultations
    'add.consultation': 'Add a new consultation',
    'list.recent.consultations': 'A list of your recent consultations',
    title: 'Title',
    'name.patients': 'Name of Patient',
    'submit.consultation': 'Add Consultation ',
    status: 'Status',
    'date.created': 'Date Created',
    action: 'Action',
    pay: 'Pay',
    'choose.patient': 'Choose patient from the list',
    specialty: 'Specialty',
    'choose.specialty': 'Choose specialty from the list',
    'consultation.help': 'Get help adding a new consultation',
    'connect.with.agent': 'Connect with an agent',
    dashboard: 'Dashboard',

    // dashboard consultations detail
    'consultation.details': 'Consultation Details',
    files: 'Files',
    'consultations.description': 'Description',
    questions: 'Questions',
    'upload.file': 'Upload a new file',
    'list.recent.files': 'A list of your recent files',
    file: 'File',
    category: 'Category',
    'case.title': 'Case title',

    //dashboard profile
    'change.password': 'Change Password',
    'current.password': 'Current Password',
    'new.password': 'New Password',
    'confirm.new.password': 'Comfirm New Password',

    whatsapp: 'Whatsapp',
    'consultation.paid':
        "This consultation is inactive because it wasn't paid!",

    //doctors-consultion
    speciality: 'Speciality',
    subspecialty: 'Subspecialty',
    'years.of.Experience': 'Years of Experience',
    'current.job': 'Current job',
    languages: 'Languages',
    nationality: 'Nationality',
    'start.the.consultant': 'Start the Consultant',
    'choose.medical.speciaity': 'Choose a medical speciaity',
    'text.search': 'Doctors from the best hospitals around the USA',
    pre: '<',
    next: '>',
    'text.doctor':
        'Tell the doctor about your condition so that he can contact you now',
    'text.title.input':
        'Briefly explain to the doctor about your condition or ocncern',
    'text.input.textarea': 'What are the symptoms you are experiencing?',
    'title.model': 'Take to a doctor now',

    //model
    'uploadfiles.modal.title2':
        'Your consultation has been sent and we will contact you',
    'uploadfiles.modal.title3':
        "Please upload the consultation's medical records",

    //gaza
    'gaza.call_1': 'CALLING ALL COMPASSIONATE DOCTORS!',
    'gaza.call_2':
        'Join us in providing vital telemedicine services to the people of Gaza. In times like these, Your expertise can make a significant difference. Partner with us today to offer remote medical second opinions and improve healthcare access for those in need. Be the change Gaza deserves. Join now!',

    //Role
    'register-volunteer': 'Volunteer Registration - Telemedicine',
    role: 'Role',
    'select.role': 'Select Role',
    provider: 'Provider',
    nurse: 'Nurse'
    // years_of_experience.required'
    // role.required
    // subspecialty.required
};
export default lang;
