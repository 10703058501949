import React from 'react';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import __ from '../../lang';
import Carousel from '../hero-carousel/carousel.component';
import lost1 from '../../assets/img/gaza/doctors_lost_1.png';
import lost2 from '../../assets/img/gaza/doctors_lost_2.png';
import lost3 from '../../assets/img/gaza/doctors_lost_3.png';
import lost4 from '../../assets/img/gaza/doctors_lost_4.png';

const Index = () => {
    let slides = [lost1, lost2, lost3, lost4];

    return (
        <div>
            {/* ------------------- Outer enclosinf div ------------------- */}
            {/* Welcome to CLINK Banner */}
            <div
                id="home"
                className="container p-4 items-center mt-16
					md:flex-row"
            >
                <div className="rounded-tl-3xl rounded-br-3xl h-14 bg-[#1C74BB]">
                    <h5 className="mr-4 mt-3 ltr:ml-4 text-2xl text-white absolute">
                        {__('welcome1')}
                    </h5>
                </div>
                {!isMobile ? (
                    <h2 className="text-3xl font-bold text-blue-950 leading-12">
                        {__('welcome2')}
                    </h2>
                ) : (
                    <h2 className="text-l font-bold text-blue-950 leading-12">
                        {__('welcome2')}
                    </h2>
                )}
                <div className="md:w-4/5 leading-loose flex flex-col gap-3">
                    <p className="text-4xl leading-1">{__('welcome3')}</p>
                </div>
                <button
                    className="mt-6 inline-block px-4 py-3 rounded-md 
			text-white bg-primary hover:bg-primary-light"
                >
                    <Link to="/login">{__('request.consult')}</Link>
                </button>
                <button
                    className="mt-6 px-4 py-3 rounded-md rtl:mr-3 ltr:ml-3
					text-white bg-primary hover:bg-primary-light
					sm:inline-block 
					md:inline-block 
					lg:inline-block"
                >
                    <Link to="/how-it-works">{__('how.works')}</Link>
                </button>
            </div>
            {/* ------------------- Calling all compassionate doctors + carousel ------------------- */}
            {/* Calling all compassionate doctors */}
            <div
                id="calling_doctors"
                className="container mx-auto p-4 grid-cols-1
					sm:grid sm:grid-cols-2 sm:items-center sm:mt-16
					md:grid md:grid-cols-2 md:items-center md:mt-16
					lg:grid lg:grid-cols-2 lg:items-center lg:mt-16"
            >
                {/* First Column Container */}
                <div
                    className="
						sm:row-start-1 sm:row-end-1
						md:row-start-1 md:row-end-1
						lg:row-start-1 lg:row-end-1"
                >
                    {/* Content */}
                    <div
                        className="ml-0 h-[440px] 
							sm:ml-0
							md:ml-0 
							lg:ml-0"
                    >
                        {/* Banner Blue backgtound */}
                        <div className="rounded-tl-3xl rounded-br-3xl h-14 bg-[#1C74BB]">
                            {
                                /* Banner Text: CALLING ALL COMPASSIONATE DOCTORS! */
                                !isMobile ? (
                                    <h5 className="mr-4 mt-3 ltr:ml-4 text-2xl text-white absolute">
                                        {__('gaza.call_1')}
                                    </h5>
                                ) : (
                                    <h5
                                        className="mr-4 mt-3 ltr:ml-4 text-white absolute
				  							sm:mr-4 sm:text-l
				  							md:mr-4 md:text-l
				  							
									"
                                    >
                                        {__('gaza.call_1')}
                                    </h5>
                                )
                            }
                        </div>
                        {/* Text */}
                        <div>
                            <p className="text-2xl items-center mt-3">
                                {__('gaza.call_2')}
                            </p>
                        </div>
                    </div>
                    {/* Buttons */}
                    <button className="mt-6 inline-block px-4 py-3 rounded-md mr-3 text-white bg-primary hover:bg-primary-light">
                        <Link to="/login">{__('request.consult')}</Link>
                    </button>
                    <button
                        className="mt-6 ml-0 px-4 py-3 rounded-md  rtl:mr-3 ltr:ml-3
							text-white bg-primary hover:bg-primary-light
							sm:inline-block 
							md:inline-block 
							lg:inline-block"
                    >
                        <Link to="/how-it-works">{__('how.works')}</Link>
                    </button>
                </div>
                <Carousel slides={slides} />
            </div>
        </div>
    );
};

export default Index;
