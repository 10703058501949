import { UseAction } from "./base-actions";
import { reducerActions } from "../reducers/utils";

export const verifyPhoneAction = (reqPayload, cb) =>
  UseAction({
    url: `v1/users/users/verify_phone/`,
    method: "put",
    action: reducerActions.doNothing,
    reqPayload,
    cb,
  });

export const uploadPatientImage = (patient, reqPayload, cb) =>
  UseAction({
    url: `v1/users/patients/${patient}/`,
    method: "patch",
    action: reducerActions.doNothing,
    reqPayload,
    cb,
  });

export const uploadConsultationFile = (reqPayload, cb) =>
  UseAction({
    url: `v1/meetings/consultation-files/`,
    method: "post",
    action: reducerActions.doNothing,
    reqPayload,
    cb,
  });
