import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

import App from './App';
import store from './config/store';
import { getCurrentLanguage } from './lang';
import reportWebVitals from './reportWebVitals';

import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App direction={getCurrentLanguage() === 'ar' ? 'rtl' : 'ltr'} />
        </Provider>
    </React.StrictMode>
);

document.documentElement.dir =
    window.localStorage.getItem('default_language') === 'en' ? 'ltr' : 'rtl';
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
