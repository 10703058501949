import en from "./en";
import ar from "./ar";

const languages = {
  ar,
  en,
};

export const getCurrentLanguage = () => {
  return window.localStorage.getItem("default_language") || "ar";
};

export const setCurrentLanguage = (lang) => {
  window.localStorage.setItem("default_language", lang);
};

const reduceFunc = function (term, variable) {
  return term.replace(/%s/, variable);
};

const getTerm = (term, lang, data) => {
  lang = lang || window.localStorage.getItem("default_language") || "ar";
  const translation =
    languages[lang][term] || languages[lang][term.toLowerCase()] || term;
  if (data && data.length > 0) {
    return data.reduce(reduceFunc, translation);
  }
  return translation;
};

export default getTerm;
