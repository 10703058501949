import React from "react";
import diagram1 from "../../assets/icon/diagram1.png";
import diagram3 from "../../assets/icon/diagram3.png";
import __ from "../../lang";

const Index = () => {
  return (
    <div className="container mx-auto flex flex-col items-center">
      <div className="title">
        <div className="text-center">
          <h5 className="text-3xl font-bold text-primary mb-4">
            {__("statistics")}
          </h5>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 mt-12 gap-4 p-4">
        <div className="flex flex-col rounded  border-dashed border-2 border-primary-light p-8">
          <div className="text-center flex flex-col gap-4 items-center justify-center">
            <img className="h-48" src={diagram1} alt="" />
            <h5 className="text-base text-primary font-bold">
              {__("statistics.one")}
            </h5>
          </div>
        </div>
        <div className="flex flex-col rounded  border-dashed border-2 border-primary-light p-8">
          <div className="text-center flex place-items-center flex-col gap-4 items-center justify-center">
            <img className="h-48" src={diagram3} alt="" />
            <h5 className="text-base text-primary font-bold mb-2">
              {__("statistics.three")}
            </h5>
          </div>
        </div>
        <div className=" flex flex-col rounded border-dashed border-2 border-primary-light p-8">
          <div className="text-center flex flex-col gap-4 items-center justify-center">
            <img className="h-48" src={diagram1} alt="" />
            <h5 className="block text-base text-primary font-bold">
              {__("statistics.two")}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
