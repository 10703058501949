import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import Card from "../../components/card";
import DashboardNav from "../../components/dashboard-nav";
import { Button } from "../../shadcn/button";
import * as actions from "../../actions/user-actions";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../shadcn/form";
import { Input } from "../../shadcn/input";
import Dialog from "../../shadcn/dialog";
import __ from "../../lang";

const FormSchema = z.object({
  current_password: z.string().min(8, {
    message: __("validation.password"),
    required_error: __("please.enter.password"),
    invalid_type_error: __("please.enter.password"),
  }),
  new_password: z.string().min(8, {
    message: __("validation.password"),
    required_error: __("enter.password"),
    invalid_type_error: __("enter.password"),
  }),
});

const Index = () => {
  const [showModal, setShowModal] = useState(false);
  const [passMatch, setPassMatch] = useState(true);
  const [confirmPassword, setconfirmPassword] = useState();
  const form = useForm({ resolver: zodResolver(FormSchema) });

  const onFinish = (values) => {
    console.log(values);
    if (confirmPassword === values.new_password) {
      setPassMatch(true);
      actions.createNewPassword(values, (res, error) => {});
      form.reset();
      setShowModal(false);
    } else {
      setPassMatch(false);
    }
  };

  return (
    <div className="grow container mx-auto items-center gap-8 md:gap-32 px-4 py-8 h-fit">
      <div className="flex flex-col gap-4">
        <DashboardNav />
        <Card className="items-start gap-8 grow" noHover={true}>
          <div className="actions flex flex-row w-full items-end justify-end">
            <Button
              onClick={() => setShowModal(true)}
              className="text-white gap-4 text-md"
            >
              {__("change.password")}
            </Button>
          </div>
          {showModal === true && (
            <Dialog visible={showModal} setVisible={setShowModal}>
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onFinish)}
                  className="space-y-6"
                >
                  <p className="text-2xl text-red-950">
                    {__("change.password")}
                  </p>
                  <FormField
                    control={form.control}
                    name="current_password"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{__("current.password")}</FormLabel>
                        <FormControl>
                          <Input placeholder="" {...field} type="password" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="new_password"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{__("new.password")}</FormLabel>
                        <FormControl>
                          <Input placeholder="" {...field} type="password" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="cnew_password"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{__("confirm.new.password")}</FormLabel>
                        <FormControl>
                          <Input
                            placeholder=""
                            {...field}
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setconfirmPassword(e.target.value)}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className=" text-red-500">
                    {passMatch ? "" : __("password.not.match")}
                  </div>
                  <Button className="text-white" type="submit">
                    {__("submit")}
                  </Button>
                </form>
              </Form>
            </Dialog>
          )}
        </Card>
      </div>
    </div>
  );
};

export default Index;
