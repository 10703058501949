import React from "react";
import __ from "../../../lang";

const Index = ({ image, title, content, subtitle, heading, attachment }) => {
  if (heading) {
    return (
      <div className="text-center my-8">
        <h1 className="text-3xl leading-relaxed font-bold text-primary">
          {__(heading)}
        </h1>
      </div>
    );
  }
  return (
    <div className="flex gap-8 flex-col md:flex-row md:odd:flex-row-reverse items-center">
      <div className="md:w-1/2 gap-2 flex-col flex">
        {title && (
          <div className="rounded-tl-3xl w-fit rounded-br-3xl h-14 bg-[#1C74BB]">
            <h2 className="text-xl lg:text-2xl flex justify-center mt-2 mx-3 leading-relaxed text-white font-bold">
              {__(title)}
            </h2>
          </div>
        )}
        {subtitle && <h5 className="text-xl text-primary">{__(subtitle)}</h5>}

        {attachment && (
          <a href="../how-it-works" target="_blank">
            <h2 className="text-xl text-primary underline">{__(attachment)}</h2>
          </a>
        )}
        {content &&
          content.map((item) => (
            <p className="text-base text-slate-500 leading-relaxed font-medium ">
              {__(item)}
            </p>
          ))}
      </div>
      {image && (
        <div className="md:w-1/2">
          <img src={image} className="w-full" alt="banner" />
        </div>
      )}
    </div>
  );
};

export default Index;
