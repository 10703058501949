import React from "react";
import __ from "../../lang";
import checkmark from "../../assets/icon/checkmark.png";

const Plans = () => {
  const data = [
    {
      name: "case1",
      check1: checkmark,
      check2: checkmark,
      check3: checkmark,
    },
    {
      name: "case2",
      check1: checkmark,
      check2: checkmark,
      check3: checkmark,
    },
    {
      name: "case3",
      check1: checkmark,
      check2: checkmark,
      check3: checkmark,
    },
    {
      name: "case4",
      check1: checkmark,
      check2: checkmark,
      check3: checkmark,
    },
    {
      name: "case5",
      check1: checkmark,
      check2: checkmark,
      check3: checkmark,
    },
    {
      name: "case6",
      check1: checkmark,
      check2: checkmark,
      check3: checkmark,
    },
    {
      name: "case7",
      check1: checkmark,
      check2: checkmark,
      check3: checkmark,
    },
    {
      name: "case8",
      check1: checkmark,
      check2: checkmark,
      check3: checkmark,
    },
    {
      name: "case9",
      check1: checkmark,
      des1: "case9.des1",
      des2: "case9.des2",
      des3: "case9.des3",
      check2: checkmark,
      check3: checkmark,
    },
    {
      name: "case10",
      rate1: "10%",
      rate2: "20%",
      rate3: "20%",
      check1: checkmark,
      check2: checkmark,
      check3: checkmark,
    },
    {
      name: "case11",
      des2: "case11.des2",
      des3: "case11.des3",
      check2: checkmark,
      check3: checkmark,
    },
    {
      name: "case12",
      check3: checkmark,
    },
    {
      name: "case13",
      check3: checkmark,
    },
  ];

  return (
    <div className="container mx-auto px-4 sm:px-8">
      <div className="py-8">
        <div>
          <h2 className="text-3xl font-semibold leading-tight text-center">
            {__("our.service")}
          </h2>
        </div>
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th
                    className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs 
                    font-semibold text-gray-700 uppercase tracking-wider"
                  ></th>
                  <th
                    className="px-5 py-3 border-b-2  bg-primary  text-left lg:text-center text-sm font-semibold
                     text-white uppercase tracking-wider"
                  >
                    {__("first.package")}
                  </th>
                  <th
                    className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left lg:text-center text-xs 
                    font-semibold text-gray-700 uppercase tracking-wider"
                  >
                    {__("second.package")}
                  </th>
                  <th
                    className="px-5 py-3 border-b-2 bg-primary text-left lg:text-center text-sm text-white
                    font-semibold
                     uppercase tracking-wider"
                  >
                    {__("third.package")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => {
                  return (
                    <tr className="bg-white">
                      <td className="bg-primary text-white text-base p-2">
                        <p className="whitespace-no-wrap">{__(item.name)}</p>
                      </td>
                      <td className="p-2 text-center">
                        <span className="inline-flex text-lg text-primary font-medium">
                          {item.rate1 ? __(item.rate1.toLowerCase()) : ""}
                          <img
                            className="h-4 w-4 mx-1 my-1"
                            src={item.check1}
                            alt=""
                          />
                        </span>
                        <p className="text-xs mt-1 text-gray-600 lg:w-48">
                          {item.des1 ? __(item.des1.toLowerCase()) : ""}
                        </p>
                      </td>
                      <td className="p-2 text-center">
                        <span className="inline-flex text-lg text-primary font-medium">
                          {item.rate2 ? __(item.rate2.toLowerCase()) : ""}
                          <img
                            className="h-4 w-4 mx-1 my-1"
                            src={item.check2}
                            alt=""
                          />
                        </span>
                        <p className="text-xs mt-1 text-gray-600 lg:w-48">
                          {item.des2 ? __(item.des2.toLowerCase()) : ""}
                        </p>
                      </td>
                      <td className="p-2 text-center">
                        <span className="inline-flex text-lg text-primary font-medium">
                          {item.rate3 ? __(item.rate3.toLowerCase()) : ""}
                          <img
                            className="h-4 w-4 mx-1 my-1"
                            src={item.check3}
                            alt=""
                          />
                        </span>
                        <p className="text-xs mt-1 text-gray-600 lg:w-48">
                          {item.des3 ? __(item.des3.toLowerCase()) : ""}
                        </p>
                      </td>
                    </tr>
                  );
                })}
                <tr className="bg-white">
                  <td className="p-2"></td>
                  <td className=" bg-primary text-white text-base p-2 text-center">
                    <span className="inline-flex text-lg font-medium">
                      $265
                    </span>
                  </td>
                  <td className=" bg-primary text-white text-base p-2 text-center">
                    <span className="inline-flex text-lg font-medium">
                      $450
                    </span>
                  </td>
                  <td className=" bg-primary text-white text-base p-2 text-center">
                    <span className="inline-flex text-lg font-medium">
                      $595
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Plans;
