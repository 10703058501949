const lang = {
    en: 'English',
    ar: 'عربي',

    //Main
    welcome1: 'مرحبا بك في كلينك',
    welcome2: 'ثقة أكبر لخطة علاجك  ',
    welcome3:
        'إستشارة طبية عبر الإنترنيت من إستشارين أمريكيين يتحدثون العربية.',
    welcome4:
        ' نحن هنا لمساعدتك في اتخاذ قرارات رعاية صحية مستنيرة ومستندة إلى أحدث الأدلة الطبية من خلال أفضل الأطباء المدربين في الولايات المتحدة والحاصلين على أعلى الشهادات العالمية في اختصاصهم.',
    welcome5:
        ' وذلك من خلال تجربة سلسة وأسعار معقولة وأعلى مستويات الدقة الطبية، نحن ملتزمون بتقديم أفضل ما لدينا من أجل صحتك .',
    welcome6: '  مرحبا بكم في ميد إكسير ، حيث صحتكم هي أهم أولوياتنا ',
    'consult.now': 'Request a Consult Now',
    'your.email': 'عنوان بريدك الإلكتروني',
    'b.doctor': 'أفضل طبيب',
    'name.doctor': 'د. شادي لاتا',
    'ceo.doctor': 'الرئيس التنفيذي clink',
    'type.doctor': 'استشاري أمراض الدم والأورام',

    //Menu
    home: 'الرئيسية',
    'about.us': 'نبذة عنا',
    'for.patients': 'للمرضى',
    'for.employers': 'لأصحاب العمل',
    'for.physicians': 'للأطباء',
    consultants: 'الأطباء الاستشاريـين',
    services: 'الخدمات',
    logout: 'تسجيل الخروج',
    'login.register': 'تسجيل دخول / حساب جديد',
    'request.consult': 'إطلب استشارة طبية',
    'contact.us': 'تواصل معنا',
    faq: 'الأسئلة الشائعة',
    'how.works': 'كيفية الحصول على الاستشارة',
    pricing: 'الأسعار',
    testimonials: 'التوصيات',
    consultation: ' أطباؤنا الاستشاريون',

    // login
    clink: 'كلينك',
    'welcome.back': ' مرحبا بك في كلينك ',
    'no.account': 'لا يوجد حساب ؟',
    register: 'إنشاء حساب جديد',
    'register-volunteer': 'تسجيل متطوع',
    'username.password.invalid': '!اسم المستخدم او كلمة المرور غير صحيحة',
    'please.enter.username': '! الرجاء إدخال اسم المستخدم',
    'please.enter.email': 'الرجاء إدخال البريدالإلكتروني',
    'enter.email': 'أدخل البريد الإلكتروني',
    'please.enter.password': '! الرجاء إدخال كلمة المرور الخاصة بك',
    'enter.password': 'أدخل كلمة المرور',
    'password.enter':
        'الرجاء إدخال بريدك الإلكتروني لإعادة تعيين كلمة المرور الخاصة بك!',
    'forget.password': 'هل نسيت كلمة المرور ؟',
    login: 'تسجيل الدخول',
    'sign.in.google': 'تسجيل الدخول بواسطة جوجل',
    'remember.me': 'تذكرني',
    submit: 'إرسال',
    email: 'البريد الإلكتروني',
    'not.valid.email': '! البريد الإلكتروني غير صالح',
    'input.email': '! الرجاء إدخال البريد الإلكتروني',
    password: 'كلمة المرور',
    'validation.password': 'يجب أن تحتوي على 8 أحرف على الأقل',
    'confirm.password': 'تأكيد كلمة المرور',
    'please.confirm.password': ' ! الرجاء تأكيد كلمة المرور الخاصة بك',
    'password.not.match': ' لا تتطابق كلمتا المرور اللتان أدخلتهما',
    phone: 'رقم الهاتف',
    'input.phone': '! الرجاء إدخال رقم هاتفك',
    type: 'اسم المريض ل',
    'input.type': 'الرجاء إدخال المسمى الخاص بك',
    patient: 'مريض',
    doctor: 'دكتور',
    me: 'أنا',
    other: 'أخرى',
    'accept.policy': 'أوافق على جميع شروط الاستخدام وسياسة الخصوصية',
    'registration.successful': '! كان تسجيلك ناجحا',
    'registration.successful.2': 'رائع ، تم إرسال تسجيلك بنجاح',
    'registration.failed': 'فشل في التسجيل',
    'registration.failed.2': ' تم فشل في التسجيل',
    'go.profile': 'انتقل إلى الملف الشخصي',
    close: 'إغلاق',
    continue: 'متابعة',
    back: 'الرجوع',
    'accept.agreement': 'يجب قبول الاتفاقية',
    'registration.succeed': 'نجح التسجيل',
    'registration.failed': 'فشل التسجيل',
    'email.exists': 'المستخدم مع هذا البريد الإلكتروني موجود بالفعل',
    'upload.profile.picture': 'قم بتحميل صورة ملفك الشخصي',
    'how.hear': 'كيف سمعت عنا ؟',
    'google.search': 'بحث جوجل',
    'by.friend': 'عن طريق صديق',
    'by.doctor': 'عن طريق طبيب',
    twiter: 'التويتر',
    instagram: 'الإنستغرام',
    snapchat: 'السناب شات',
    facebook: 'الفيس بوك',
    whatsApp: 'الواتساب',

    //request call
    'next.step': 'ماذا تريد أن تفعل بعد ذلك',
    'request.call': 'طلب مكالمة',
    'enter.manually': 'أدخل المعلومات يدويا',

    'consultation.info': 'معلومات الاستشارة',
    'who.is.this.for': 'هذا من أجل',
    'speciality.account': 'حساب متخصص',
    'verify.phone': 'التحقق من رقم هاتفك',
    'please.verify.your.phone': 'الرجاء التحقق من رقم هاتفك',
    'verify.phone.required': 'التحقق من رقم الهاتف مطلوب',

    // Demographics Patient
    demographics: 'البيانات الشخصية',
    'details.data': 'تفاصيل البيانات',
    'thank.you': 'شكرا',
    'first.name': 'الاسم الأول',
    'middle.name': 'الاسم الأوسط',
    'last.name': 'اسم العائلة',
    address_1: 'العنوان 1',
    'address.2': ' العنوان 2',
    city: 'المدينة',
    'profile.picture': 'صورة الملف الشخصي',
    country: 'الدولة',
    zip: 'الرمز البريدي',
    state: 'المقاطعة',
    'date.birth': 'تاريخ الميلاد',
    male: 'ذكر',
    female: 'أنثى',
    gender: 'الجنس',
    continue: 'متابعة',
    'first.name.required': 'الاسم الأول مطلوب',
    'last.name.required': 'اسم العائلة مطلوب',
    'address.1.required': ' العنوان 1 مطلوب',
    'city.required': 'المدينة مطلوبة',
    'country.required': 'الدولة مطلوب',
    'zip.required': 'الرمز البريدي مطلوب',
    state_required: 'الدولة مطلوبة',
    'gender.required': 'الجنس مطلوب',
    'first.name.string': 'يجب أن يكون الاسم الأول سلسلة حروف',
    'last.name.string': 'اسم العائلة يجب أن يكون  سلسلة حروف',
    'gender.name.string': 'يجب أن يكون الجنس سلسلة حروف',
    // Speciaility for Patient
    'specialty.account': 'حساب متخصص',
    speciaility: 'التخصص',
    'select.speciaility': 'حدد تخصصا',
    'choose.the.best': 'سوف نختار أفضل الأطباء لك',
    'preferred.consultant': 'الاستشاري المفضل',
    'select.consultant': 'حدد الاستشاري',

    //services
    'select.services': 'اختر الخدمة التي تناسب احتياجاتك وتفضيلاتك',
    'choose.services': 'اختر الباقة التي تناسب احتياجاتك وتفضيلاتك',
    'our.service': 'الخدمات',
    'first.package': 'الباقة الأولى',
    'second.package': 'الباقة الثانية',
    'third.package': 'الباقة الثالثة',
    case1: 'إنشاء ملف طبي خاص بكل مريض',
    case2: 'استلام جميع التقارير والتحاليل والاستفسارات المرسلة',
    case3: ' تعيين طبيب منسق خاص لكل مريض للتأكد من اكتمال المعلومات الطبية المتعلقة بالحالة ',
    case4: 'تعيين استشاري من الولايات المتحدة لمتابعة الحالة',
    case5: 'مراجعة الحالة والملفات الطبية المرفقة بها من قبل الاستشاري',
    case6: 'تزويد المريض بتقرير مفصل عن الرأي الطبي الثاني ',
    case7: 'خطة علاجية خاصة بالمريض متوافقة مع المعايير الطبية الامريكية',
    case8: 'الإاجابة على جميع استفسارات المريض والفريق  المعالج',
    case9: 'متابعة الحالة الطبية مجاناً بعد الحصول على الرأي الطبي الثاني (تواصل بالرسائل)',
    'case9.des1': 'خلال أسبوع من الحصول على الاستشارة',
    'case9.des2': 'خلال أسبوعين من الحصول على الاستشارة ',
    'case9.des3': 'خلال ثلاثة شهور  من الحصول على الاستشارة',
    case10: 'تخفيض على  استشارة مستقبلية ',
    case11: 'التواصل مع الاستشاري المشرف من خلال مكالمة فيديو',
    'case11.des2': 'مكالمة فيديو واحدة لمدة ٢٠ دقيقة',
    'case11.des3':
        '٦ مكالمات فيديو  لمدة ١٥ دقيقة اكل مكالمة مع الاستشاري كل أسبوعين  ',
    case12: 'مراجعة الصور والملفات الشعاعية المتعلقة بالحالة من قبل استشاري أشعة في الولايات المتحدة ',
    case13: 'الحصول على تقرير جديد بعد مراجعة الحالة من قبل استشاري الأشعة ',
    'choose.service': 'اختر الخدمة',

    //Patient Case Page1
    'title.case': 'عنوان الحالة ',
    'name.patient': 'اسم المريض',
    allergies: 'الحساسية',
    'past.medical.history': 'التاريخ الطبي الماضي',
    description: 'وصف الحالة',
    'past.surgical.history': 'التاريخ الجراحي الماضي',
    'questions.consultant': 'أسئلتكم للاستشاري ',
    'title.case.required': 'عنوان الحالة المرضية مطلوب',
    'name.patien.required': 'اسم المريض مطلوب',
    upload: 'تحميل',
    'upload.size': 'يجب أن تكون الصورة أصغر من 2 ميجابايت',
    'upload.type': 'يمكنك فقط تحميل ملف jpg / png',

    //Patient Case Page2
    when_problem_start: 'متى بدأت المشكلة ؟',
    'what.test.done': 'ما هو الاختبار الذي تم إجراؤه ؟',
    'what.symptoms.bothering':
        'ما هي الأعراض التي تزعج المريض أكثر من غيرها الآن ؟',
    'what.treatment.received': 'ما العلاج الذي تم تلقيه ؟',
    'What.treatment.patient.now': 'ما علاج المريض الآن ؟',

    // FAQ
    'frequently.question': 'الأسئلة الشائعة',
    'q.first.opinion': 'ما هو الرأي الطبي الثاني ؟',
    'a.first.opinion':
        'الرأي الطبي الثاني هو تقييم أو استشارة طبية يتم الحصول عليها من طبيب آخر بعد استشارة طبيب أول. ُيطلب الرأي الطبي الثاني عادة عندما يحتاج المريض إلى تقييم إضافي أو رأي طبي آخر بشأن تشخيصه أو خطة العلاج المقترحة ليزداد المريض طمأنينة على صحته ومسار علاجه. يعزز الرأي الطبي الثاني تشخيص المرض ويوفر رؤية إضافية وتوجيها مهما للمريض، وقد يساعد في اتخاذ قرارات صحية مستنيرة وتحسين الرعاية الصحية العامة',
    'q.second.opinion': 'ما هي أهمية الرأي الطبي الثاني ؟',
    'a.second.opinion.a':
        'أ- التأكد من التشخيص الصحيح: يساعد الرأي الطبي الثاني في تأكيد التشخيص المطروح والتأكد من صحته. إذ قد يحدث خطأ تشخيصي في بعض الأحيان، وبالتالي فإن الحصول على آراء متعددة من أطباء مختلفين يمكن أن يساعد في زيادة الثقة في التشخيص الأول المقدم',
    'a.second.opinion.b':
        'ب- تقييم الخيارات العلاجية: يمكن أن يساعد الاستشاري الثاني في تقييم الخيارات العلاجية المتاحة وتوفير رؤية جديدة وتوجيهات مهمة. قد يعرض الطبيب الثاني خيارات علاجية مختلفة أو يوفر معلومات إضافية حول فوائد ومخاطر العلاج المقترح، وكل ما كان ذلك أسرع كان في مصلحة المريض.',
    'a.second.opinion.c':
        'ج- الاطمئنان النفسي: يمكن أن يوفر الرأي الطبي الثاني الاطمئنان النفسي للمريض وتخفيف القلق والشكوك التي قد تكون موجودة بشأن التشخيص أو العلاج. يمكن أن يعزز الرأي الطبي الثاني الثقة والارتياح لدى المريض ويساعده في اتخاذ قرارات أكثر ثقة بشأن رعايته الصحية',
    'a.second.opinion.d':
        'د- تحسين النتائج الصحية: يؤدي الحصول على الرأي الطبي الثاني إلى تحسين النتائج الصحية للمريض. عن طريق الحصول على رأي إضافي من طبيب متخصص آخر، يمكن تحديد أفضل الإجراءات والتدابير للعناية بالمريض وتحقيق أفضل نتائج صحية.',
    'q.third.opinion':
        'كم من الوقت يستغرق الحصول على الاستشارة طبية من ميد إكسير ؟',
    'a.third.opinion':
        'نحن نبذل قصارى جهدنا لتقديم الاستشارة الطبية في الوقت المناسب، يتلقى معظم المرضى استشارتهم الطبية في غضون  ٧-٥أيام عمل من بعد استلام كامل المعلومات عن الحالة الطبية وقد تختلف تبعا لمدى تعقيد الحالة وتوافر جميع السجلات الطبية ذات الصلة بحالتك',
    'q.fourth.opinion': 'ما هي تكلفة الاستشارة الطبية عبر موقعنا؟',
    'a.fourth.opinion':
        'إن مهمتنا الأولى هي صحتكم، حيث نسعى من خلال خدماتنا لتقديم أفضل الاستشارات الطبية وبأسعار مناسبة للجميع، يمكنكم الاطلاع على خدمات ميدإكسر والأسعار من هنا.',
    'q.fifth.opinion':
        'هل سوف يساعد التأمين في دفع تكاليف الاستشارة الطبية المقدمة من ميد إكسير؟',
    'a.fifth.opinion':
        'للأسف، أغلب أنواع التأمين الصحي لا تغطي تكاليف الاستشارة الطبية. لذا سيكون المريض هو المسؤول عن هذه التكاليف عند طلب الاستشارة',
    'q.sixth.opinion': 'ما هي لغة السجلات الطبية التي يمكنك تزويدنا بها؟',
    'a.sixth.opinion':
        'عند طلبك استشارة، نطلب منك رفع الملفات والسجلات الطبية المتعلقة بحالتك الصحية، يمكن لهذه الملفات أن تكون باللغة العربية أو الإنجليزية، وإن كانت من لغات أخرى يرجى ترجمتها للإنجليزية قبل إرسالها',
    'q.seventh.opinion': ' من يختار الطبيب الذي يقدم الاستشارة ؟',
    'a.seventh.opinion':
        'بعد مراجعة طلبك والسجلات الطبية المرفقة بها، سيقوم فريقنا بمراجعة حالتك و عرضها علي لجنة من استشاريين ميد إكسير من أجل حصولك على إستشارة طبية',
    'q.eighth.opinion':
        'هل يحمل الأطباء في ميد إكسير المؤهلات اللازمة لتقديم الاستشارات الطبية؟',
    'a.eighth.opinion':
        'نعم، فريقنا يتألف من أفضل الأطباء الحاصلين على أعلى الشهادات والتدريب المميز في الولايات المتحدة الأمريكية ولديهم الكفاءة والخبرة العالية في تخصصاتهم الطبية ويلتزمون بأعلى معايير الدقة الطبية والاحترافية',
    'q.ninth.opinion': 'كيف يعمل ميد إكسير؟',
    'a.ninth.opinion':
        'يوفر ميد إكسير خدمات سهلة الوصول من أجل الحصول على الاستشارات الطبية. قم بتقديم سجلاتك الطبية والمعلومات ذات الصلة، استشر استشا ًريا معتمدا، استلم تقريرا مفص ًلا، واستفد من الدعم المستمر',

    //Statistics
    statistics: 'الإحصاءات',
    'set.statistics': 'مجموعة من الإحصاءات الموثوقة',
    'statistics.one':
        '٥٠٪ من الأخطاء التشخيصية يمكن أن تتجنب من خلال الرأي الطبي الآخر',
    'statistics.one.p':
        '٨٨ بالمائة من التشخيصات الأولية يتم تغييرها أو تحسينها',
    'statistics.two':
        '٥٠٪ من المرضى يتجنبون إجراء جراحة غير ضرورية بعد الحصول على رأي طبي آخر',
    'statistics.two.p': '٧.٤ مليون يتم تشخيصهم بشكل خاطئ سنوياً',
    'statistics.three':
        '٨٨٪ من الحالات الطبية يتم تغيير تشخيصها الأولي و بالتالي علاجها',
    'statistics.three.p': '٦٧٪ يغيرون خططهم العلاجية بعد الحصول على رأي ثاني',

    //About Us
    'about.us': 'من نحن ؟',
    'care.for': 'نحن نهتم بدعم المرضى والتعامل مع الحالات الصعبة',
    'p.about.us':
        ' شبكة من أفضل الأطباء الاستشاريين في الولايات المتحدة الأمريكية المتحدثين باللغة العربية ومن جميع التخصصات، نسعى لتقديم الاستشارات الطبية والرعاية الصحية ذات الجودة العالية للمرضى في العالم العربي . يعمل استشاريونا في نخبة من المشافي الأمريكية كما يملكون خبرة واسعة في دعم المرضى والتعامل مع الحالات الصعبة و النادرة وفقا للمعايير الطبية الأمريكية',
    'read.more': 'اقرأ أكثر',
    'years.experience': 'سنوات من الخبرة',

    //Our vision
    'our.vision': 'رؤيتنا',
    'our.vision.text':
        ' نسعى لنكون المنصة الأولى المتكاملة في منطقة الشرق الأوسط وشمال إفريقيا التي تقدم الاستشارات الطبية من استشاريين أمريكيين .كما نعمل جاهدين لتوفير الرعاية الصحية للمرضى في أي مكان وزمان، وهذا عبر مجموعة من الأطباء الاستشاريين في ُمختلف المجالات الطبية ',
    'welcome.clink':
        'مرحًبا بك في كلينك: منصة الرأي الثاني الموثوقة للمرضى الناطقين بالعربية في منطقة الشرق الأوسط وشمال إفريقيا',
    'power.caption': 'أطلق العنان لقوة المعرفة اكتشف خدمات كلينك الثورية',
    'tag.line': 'الوضوح الطبي ، رأي واحد في وقت واحد',
    //Mission Statement
    'mission.statement': 'بيان المهمة',
    'mission.statement.text':
        'تزويد المرضى باستشارات طبية من قبل استشاريينا في الولايات المتحدة الأمريكية، وتقديم خدمة متكاملة بأرقى المعايير الطبية العالمية وبأسعار مناسبة.',
    //Vision Statement
    'vision.statement': 'بيان الرؤية',
    'our.vision.statement':
        'أن نكون المزود الرائد للاستشارات الطبية للرأي الثاني ، مما أحدث ثورة في الوصول إلى الرعاية الصحية للمرضى الناطقين باللغة العربية في منطقة الشرق الأوسط وشمال إفريقيا من خلال التكنولوجيا المتقدمة والأطباء الموثوق بهم والقيمة الاستثنائية',
    //faq
    'faq.header': 'الأسئلة الشائعة',
    faq: 'الأسئلة الشائعة حول كلينك والرأي الثاني',
    'faq.1':
        'ما هو كلينك؟ كلينك هي منصة موثوقة للرأي الطبي الثاني تربط المرضى الناطقين بالعربية في منطقة الشرق األوسط وشمال إفريقيا مع األطباء الحاصلين على تدريب في الواليات المتحدة الأمريكية، وتقدم استشارات طبية متخصصة',
    'faq.2':
        'كيف يعمل كلينك؟ يوفر كلينك عملية سلسة للحصول على الرأي الطبي الثاني. قم بتقديم سجالتك الطبية والمعلومات ذات الصلة، استشر استشارًيا معتمًدا، استلم  تقريرا مفصاًل، واستفد من الدعم المستمر',
    'faq.3':
        'كم يستغرق الحصول على الرأي الطبي الثاني؟ قد يختلف الوقت الالزم حسب تعقيد حالتك الطبية. ومع ذلك، نسعى لتقديم ردود في الوقت المناسب ونهدف إلى تقديم الرأي الطبي الثاني في إطار زمني معقول',
    'faq.4':
        'هل يحمل الأطباء في كلينك المؤهالت الالزمة؟ نعم، فريقنا يتألف من أطباء حاصلين على تدريب في الواليات المتحدة الأمريكية ولديهم الكفاءة والخبرة العالية في تخصصاتهم الطبية ويلتزمون بأعلى معايير الدقة الطبية والاحترافية',
    'faq.5':
        'لماذا يجب أن أطلب رأًيا طبًيا ثانًيا؟ يوفر الرأي الطبي الثاني رؤى طبية إضافية ومنظورات مختلفة، مما يمّكنك من بشأن رعايتك الصحية وخيارات العلاج',

    //our story
    'our.story': 'قصتنا',
    'our.story1':
        'في كلينك ، نحن متحمسون لتحويل الرعاية الصحية من خلال قوة الآراء الثانية. نحن نتفهم التحديات التي يواجهها المرضى الناطقون باللغة العربية في منطقة الشرق الأوسط وشمال إفريقيا عند اتخاذ قرارات طبية حاسمة. تعمل منصتنا على سد هذه الفجوة من خلال توفير مصدر موثوق للآراء الثانية الدقيقة',
    'our.story2':
        'فريقنا الموقر من الأطباء المدربين في الولايات المتحدة يجلب خبراتهم لتقديم آراء ثانية شاملة وموثوقة. نحن ملتزمون بتمكين المرضى بالمعرفة اللازمة لاتخاذ قرارات مستنيرة بشأن صحتهم',
    'our.story3':
        'بنيت على الثقة والرحمة والنزاهة ، ميديكسير يخلق بيئة داعمة للمرضى الذين يبحثون عن آراء ثانية. نحن نخصص التجربة ، ونضمن حصول كل مريض على الوضوح والفهم ووجهات النظر الطبية المتنوعة',
    'our.story4':
        'انضم إلينا في هذه الرحلة التحويلية حيث تلتقي الخبرة الطبية بالرعاية الرحيمة. كلينك هو الضوء التوجيهي الخاص بك ، وتمكينك من اتخاذ قرارات الرعاية الصحية واثقة ومستنيرة. رفاهيتك هي أولويتنا القصوى',

    //HOW WORKS
    'seeking.mena':
        'البحث عن رأي ثان سهل للمرضى الناطقين باللغة العربية في منطقة الشرق الأوسط وشمال إفريقيا...',
    step1: '1- التسجيل واٍنشاء حساب جديد :',
    'step.1':
        'تفضل بزيارة المنصة الإلكترونية وأنشئ حسابا جديدا، أدخل معلومات أساسية مثل الاسم والبريد الالكتروني ورقم الهاتف',
    step2: ' 2- إضافة مريض: ',
    'step.2':
        'بعد تسجيل الدخول بنجاح، قم بالانتقال إلى قسم المرضى وأضف معلومات المريض الجديد، بإمكانك إضافة أكثر من مريض إلى نفس الحساب',
    step3: ' 3- طلب استشارة جديدة : ',
    'step.3':
        'انتقل إلى قسم الاستشارات حيث يمكنك طلب استشارة طبية، يمكنك اختيار المريض الذي ترغب في طلب الاستشارة له.',
    step4: '4- اختيار نوع الاستشارة و إتمام عملية الدفع : ',
    'step.4':
        'بعد النقر على إضافة استشارة جديدة، اختر الخدمة و قم بإتمام عملية الدفع، ثم قم بتعبئة المعلومات الطبية عن الحالة ورفع التقارير الطبية.',
    step5: '5- مراجعة الاستشارة الطبية :',
    'step.5':
        'بمجرد تسجيل استشارتك بنجاح، سيتم تعيين طبيب منسق خاص بك، سيقوم الطبيب المنسق بالتواصل معك للتأكد من اكتمال كافة المعلومات والسجلات الطبية. ثم سيتم نقلها إلى الطبيب الاستشاري المناسب.',
    step6: '6- تقرير الاستشارة الطبية :',
    'step.6':
        'سيقوم الطبيب الاستشاري بمراجعة حالتك وتقاريرك الطبية بعناية، ثم يقوم بكتابة تقرير مفصل يحتوي على الاستشارة الطبية اعتمادا على أحدث المعايير الطبية الأمريكية.',
    step7: '7- الحصول على معلومات الاستشارة:',
    'step.7':
        ' بعد اكتمال كتابة التقرير الطبي، سيتم إضافته إلى حسابك على موقعنا كما سيتم إرسال نسخة عن التقرير عبر البريد الالكتروني. ',
    step8: '8- اٍذا اخترت خدمة التواصل مع الاستشاري من خلال مكالمة فيديو',
    'step.8':
        ' سيتم تحديد موعد مناسب لك للاتصال مع الاستشاري و مناقشة حالتك و الاجابة عن جميع تساؤلاتك',
    step9: '9- متابعة الاستشارة والاستفسارات:',
    'step.9':
        'اذا كان لديك أي سؤال أو استفسار حول طلبك، أو تريد تسجيل استشارة طبية ثانية، فريق كلينك جاهز لمساعدتك دائما',

    // Why us
    'why.us': 'لماذا نحن ؟',
    'why.text':
        ' نوفر من خلال موقعنا الاستشارات الطبية تحت إشراف أفضل الأطباء حول العالم، تستطيع طلب الاستشارات الطبية في أي وقت و من أي مكان ودون الحاجة للسفر، حيث سيقوم فريقنا بمراجعة كل جوانب الحالة ثم إعداد تقرير شامل يتضمن تفاصيل الاستشارة الطبية وتوصيات العلاج وهذا ما يوفر الطمأنينة لك ولعائلتك',
    'why.texttwo':
        'الصحية في أي وقت ودون الحاجة للسفر، حيث سيعمل فريقنا بمراجعة كل جوانب الحالة ثم إعداد كتيب شامل بما',
    'why.textthree':
        'يخص الرأي الثاني والذي يتضمن تفاصيل االستشارة الطبية وتوصيات العالج والوقاية، وهذا ما يوفر الطمأنينة لك ولعائلتك',

    // Mission
    mission: 'رسالتنا :',
    mission_text:
        'توفير آراء طبية ثانية سهلة الوصول وبأسعار معقولة من قبل أفضل الأطباء الاستشاريين في الولايات المتحدة، نسعى لتقديم رعاية صحية وخدمة استثنائية للمرضى في العالم العربي من خلال تكنولوجيا التطبيب عن بعد',

    //consultants menu:
    specialities: 'الاستشاريـين',
    'consultants.profiles': 'الملفات الشخصية للاستشاريـين',

    // services menu:
    individuales: 'للأفراد',
    'clinics.hospitals': 'للعيادات والمستشفيات',
    employers: 'للموظفون',

    // articles menu:
    articles: 'التخصصات',
    oncology: 'طب الأورام',
    cardiology: 'طب القلب',
    endocrinology: 'طب الغدد الصماء',
    rheumatology: 'طب الأمراض المفصلية',
    Pulmonology: 'طب الرئة',
    orthopedics: 'جراحة العظام',
    Pediatrics: 'طب الأطفال وحديثي الولادة',
    'obstetrics.gynecology': 'طب النساء والتوليد',
    'infectious.diseases': 'طب الأمراض المعدية',

    //Carousel
    'title.one': 'أفضل المدربين في الولايات المتحدة',
    'title.two': 'مستشارونا أعضاء في جمعيات أمريكية رائدة',

    // Footer
    request: 'اطلب',
    free_consult: 'استشارة مع أخصائي',
    now: 'الآن',
    code: 'طلب كود',
    enter_phone: 'أدخل رقم هاتفك',
    rights: 'ميداكسير. جميع الحقوق محفوظة.',
    'terms.policy': 'الشروط · سياسة الخصوصية',
    products: 'المنتجات',
    resources: 'الموارد',
    company: 'الشركة',
    support: 'الدعم',
    'drag.drop': 'السحب والإفلات',
    visual: 'Visual Studio X',
    'easy.content': 'المحتوى السهل',
    'industries.tools': 'الصناعات والأدوات',
    cases: 'حالات الاستخدام',
    blog: 'المدونة',
    'online.event': 'حدث عبر الإنترنت',
    exercitation: 'ممارسة نوسترود',
    'diversity.inclusion': 'التنوع والشمول',
    'about.us.f': 'من نحن',
    press: 'الصحافة',
    'customer.stories': 'قصص العملاء',
    'online.communities': 'المجتمعات عبر الإنترنت',
    documentation: 'الوثائق',
    'tutorials.guides': 'دروس وأدلة',
    webinars: 'ندوات عبر الإنترنت',
    'open.source': 'المصدر المفتوح',

    // payment
    'payment.info': 'معلومات الدفع الآمنة',
    'name.card': 'الاسم على البطاقة',
    'number.card': 'رقم البطاقة',
    'exp.card': 'تاريخ انتهاء الصلاحية',
    'security.code': 'رمز الأمان',
    'pay.now': 'ادفع الآن',

    //AboutUsRequirement
    'requirement.account': 'متطلبات الحساب',
    specific: 'الأطباء متطلبات محددة / استبيان',
    'add.item': 'إضافة عنصر',
    'enter.item': 'الرجاء إدخال العنصر',
    'measuring.blood': 'قياس ضغط الدم',
    neurological: 'الفحص العصبي',
    psychiatric: 'التقييم النفسي',
    'digital.rectal': 'فحص المستقيم الرقمي',

    // Header Side
    'connects.patients': 'ربط المرضى من جميع أنحاء العالم',
    'e.sign': 'الإفصاح عن قانون التوقيع الإلكتروني والموافقة عليه',
    health: 'مرحبا بكم في الصحة ! يركز هذا الموقع على — لقد خمنت ذلك - الصحة',

    //Cities
    jeddah: 'جدة',
    'mekkah ': 'مكة',
    dubai: 'دبي',
    'abu.dhabi': 'أبو ظبي',
    cairo: 'القاهرة',

    //dr. shadi
    'dr.name': 'د. شادي لاطه',
    ceo: 'المدير التنفيذي، كلينك',
    consultan: 'استشاري أمراض دم و أورام',

    //dashboard
    'add.patient': 'إضافة مريض جديد',
    'list.recent.patients': 'قائمة المرضى الخاص بك مؤخرا',
    'pick.date': 'اختر تاريخ',
    delete: 'حذف المريض',
    'delete.patient': 'حذف المريض',
    'delete.consultation': 'حذف الاستشارة',
    avatar: 'صورة المريض',
    patients: 'المرضى',
    consultations: 'الاستشارات',
    profile: 'الملف الشخصي',
    'first.name.is.required': 'الاسم الأول مطلوب',
    'submit.patient': 'اٍضافة مريض',

    //dashboard consultations
    'add.consultation': 'إضافة استشارة جديدة',
    'list.recent.consultations': 'قائمة الاستشارات الأخيرة الخاصة بك',
    title: 'العنوان',
    status: 'الحالة',
    'name.patients': 'اسم المريض',
    'submit.consultation': 'اٍضافة استشارة',
    'date.created': 'تاريخ الإنشاء',
    action: 'الإجراء',
    pay: 'الدفع',
    'choose.patient': 'اختيار المريض من القائمة',
    specialty: 'التخصص',
    'choose.specialty': 'اختيار التخصص من القائمة',
    'consultation.help': 'احصل على مساعدة في إضافة استشارة جديدة',
    'connect.with.agent': 'تواصل مع مندوبنا',
    dashboard: 'صفحة التحكم',

    // dashboard consultations detail
    'consultation.details': 'تفاصيل الاستشارة',
    files: 'الملفات',
    'consultations.description': 'الوصف',
    questions: 'أسئلة',
    'upload.file': 'تحميل ملف جديد',
    'list.recent.files': 'قائمة الملفات الأخيرة',
    file: 'ملف',
    category: 'الفئة',
    'case.title': 'عنوان الحالة',

    //dashboard profile
    'change.password': 'تغير كلمة السر',
    'current.password': 'الكلمة السر الحالية',
    'new.password': 'كلمة السر الجديدة',
    'confirm.new.password': 'تأكيد كلمة السر الجديدة',

    whatsapp: 'واتس آب',
    'consultation.paid': '!هذه الاستشارة غير مدفوعة بعد',

    //doctors-consultion
    speciality: 'الاختصاص',
    subspecialty: 'الاختصاص الدقيق الفرعي',
    'years.of.Experience': 'سنوات الخبرة',
    'current.job': 'موقع العمل الحالي',
    languages: 'اللغات',
    nationality: 'الجنسية',
    'start.the.consultant': 'أطلب الاستشارة',
    'choose.medical.speciaity': 'اختر مستشارك',
    'text.search': 'لدينا أفضل الأطباء في الولايات المتحده الأمريكية',
    pre: '<',
    next: '>',
    'text.doctor': 'أخبر الطبيب عن حالتك حتى يتمكن من الاتصال بك الآن',
    'text.title.input': 'اشرح للطبيب بإيجاز حالتك أو مخاوفك',
    'text.input.textarea': 'ما هي الأعراض التي تعاني منها؟',
    'title.model': 'تحدث لطبيب الان ',

    //model
    'uploadfiles.modal.title2': 'تم ارسال استشارتك وسيتم التواصل معك',
    'uploadfiles.modal.title3': 'يرجى تحميل السجلات الطبية الخاصة بالاستشارة',

    //gaza
    'gaza.call_1': 'نداء لجميع الأطباء',
    'gaza.call_2':
        'انضموا إلينا لتقديم خدمات الطب عن بُعد الأهل غزة. يمكن لخبرتكم ان تنقذ حياة. انضموا إلينا اليوم لتقديم الآراء الطبية عن بُعد وتحسين وصول الرعاية الصحية لاهلنا في غزة . انضموا الآن'
};

export default lang;
