import React, { useEffect } from "react";

const ContactUs = () => {
  const lang = window.localStorage.getItem("default_language") || "ar";

  useEffect(() => {
    if (lang == "en") {
      const frame = document.createElement("iframe");
      frame.src =
        "https://forms.zohopublic.com/medexir/form/ContactUs/formperma/Os9un7vGhSBc_DeLpv" +
        "xplgycQxxte763oSNTEBdWtpU?zf_rszfm=1";
      frame.style.border = "none";
      frame.style.height = "523px";
      frame.style.width = "90%";
      frame.style.transition = "all 0.5s ease";
      const newDiv = document.getElementById(
        "zf_div_Os9un7vGhSBc_DeLpvxplgycQxxte763oSNTEBdWtpU",
      );
      if (newDiv.getAttribute("rel") === "rendered") {
        return;
      }
      newDiv.appendChild(frame);
      newDiv.setAttribute("rel", "rendered");
      window.addEventListener(
        "message",
        function (event) {
          var evntData = event.data;
          if (evntData && evntData.constructor == String) {
            var zf_ifrm_data = evntData.split("|");
            if (zf_ifrm_data.length == 2) {
              var zf_perma = zf_ifrm_data[0];
              var zf_ifrm_ht_nw = parseInt(zf_ifrm_data[1], 10) + 15 + "px";
              var iframe = document
                .getElementById(
                  "zf_div_Os9un7vGhSBc_DeLpvxplgycQxxte763oSNTEBdWtpU",
                )
                .getElementsByTagName("iframe")[0];
              if (
                iframe.src.indexOf("formperma") > 0 &&
                iframe.src.indexOf(zf_perma) > 0
              ) {
                var prevIframeHeight = iframe.style.height;
                if (prevIframeHeight != zf_ifrm_ht_nw) {
                  iframe.style.height = zf_ifrm_ht_nw;
                }
              }
            }
          }
        },
        false,
      );
    } else {
      const frame = document.createElement("iframe");
      frame.src =
        "https://forms.zohopublic.com/medexir/form/Untitled/formperma/6D-CRJvH7yEnjf2mV9q" +
        "csB7yGENsuxQfdV0y787ip1s?zf_rszfm=1";
      frame.style.border = "none";
      frame.style.height = "523px";
      frame.style.width = "90%";
      frame.style.transition = "all 0.5s ease";

      const newDiv = document.getElementById(
        "zf_div_6D-CRJvH7yEnjf2mV9qcsB7yGENsuxQfdV0y787ip1s",
      );
      if (newDiv.getAttribute("rel") === "rendered") {
        return;
      }
      newDiv.appendChild(frame);
      newDiv.setAttribute("rel", "rendered");
      window.addEventListener(
        "message",
        function (event) {
          var evntData = event.data;
          if (evntData && evntData.constructor == String) {
            var zf_ifrm_data = evntData.split("|");
            if (zf_ifrm_data.length == 2) {
              var zf_perma = zf_ifrm_data[0];
              var zf_ifrm_ht_nw = parseInt(zf_ifrm_data[1], 10) + 15 + "px";
              var iframe = document
                .getElementById(
                  "zf_div_6D-CRJvH7yEnjf2mV9qcsB7yGENsuxQfdV0y787ip1s",
                )
                .getElementsByTagName("iframe")[0];
              if (
                iframe.src.indexOf("formperma") > 0 &&
                iframe.src.indexOf(zf_perma) > 0
              ) {
                var prevIframeHeight = iframe.style.height;
                if (prevIframeHeight != zf_ifrm_ht_nw) {
                  iframe.style.height = zf_ifrm_ht_nw;
                }
              }
            }
          }
        },
        false,
      );
    }
  }, []);

  return (
    <div className="grow container gap-8 md:gap-32 mx-auto p-4 flex flex-col md:flex-row">
      {lang === "en" ? (
        <div
          id="zf_div_Os9un7vGhSBc_DeLpvxplgycQxxte763oSNTEBdWtpU"
          className="mx-auto w-3/4"
        ></div>
      ) : (
        <div
          id="zf_div_6D-CRJvH7yEnjf2mV9qcsB7yGENsuxQfdV0y787ip1s"
          className="mx-auto w-3/4"
        ></div>
      )}
    </div>
  );
};
export default ContactUs;
