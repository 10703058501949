import { combineReducers } from "redux";
import axios from "axios";
import logger from "redux-logger";
import store from "../config/store";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // debugger;
    // if ( error.response.status === 401) {
    //   window.location.href = '/login'
    // }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

const { dispatch } = store;

export const UseAction = (options) => {
  const token = window.localStorage.getItem("token");
  if (token) {
    axios.defaults.headers.common["Authorization"] =
      `Bearer ${window.localStorage.getItem("token")}`;
  }

  const imageOfPatient = window.localStorage.getItem("patientImage");
  if (
    imageOfPatient !== null ||
    imageOfPatient !== undefined ||
    imageOfPatient !== ""
  ) {
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
  }

  // console.log("user-actions token: ",token);
  const { normalAction, action, url, method, reqPayload, payload, cb } =
    options;

  if (normalAction) {
    dispatch(action(payload));
    cb && cb(false, false);
    return;
  }

  const fullurl = `${process.env.REACT_APP_API_URL}${url}`;
  // console.log('base-actions process.env.REACT_APP_API_URL: ', process.env.REACT_APP_API_URL);
  // console.log('base-actions fullurl: ', fullurl);
  let req;

  if (method === "post" || method === "put" || method === "patch") {
    req = axios[method](fullurl, reqPayload);
  } else if (method === "delete") {
    req = axios.delete(fullurl);
  } else {
    req = axios.get(fullurl);
  }
  req
    .then((response) => {
      // dispatch data to reducer
      dispatch(action(response.data || payload));
      // if callback function exists then pass the response to it and call it.
      cb && cb(response, false);
    })
    .catch((error) => {
      cb && cb(error, true);
    });
};
