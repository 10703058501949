import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Input, Form, Alert } from "antd";
import { Link } from "react-router-dom";
import __ from "../../lang";
import * as actions from "../../actions/user-actions";
import medLogoen from "../../assets/img/login/clink_logo_03.png";
import medLogoar from "../../assets/img/login/clink_logo_03.png";

const Login = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  const getIdFromJwt = (token) => {
    const decode = jwt_decode(token);
    actions.setUserIdAction(decode.user_id);
  };

  const onFinish = (values) => {
    actions.loginAction(values, (res, error) => {
      if (error === true) {
        setError(true);
      } else {
        getIdFromJwt(res.data.access);
        window.location.href = "/dashboard";
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const logoOnChangelang = () => {
    const lang = window.localStorage.getItem("default_language") || "ar";
    if (lang === "ar") {
      return (
        <img
          className="mx-auto h-10 w-auto"
          src={medLogoar}
          alt="Your Company"
        />
      );
    } else {
      return (
        <img
          className="mx-auto h-10 w-auto"
          src={medLogoen}
          alt="Your Company"
        />
      );
    }
  };

  return (
    <div className="bg-white flex justify-center items-center py-16 p-4">
      <div className="container mx-auto md:w-1/4 bg-white shadow-xl rounded-md overflow-hidden">
        <div className="w-full flex justify-center items-center">
          <div className="w-full flex flex-col justify-center">
            <div className="bg-primary p-4">{logoOnChangelang()}</div>
            <div className="p-4">
              <h3 className="text-xl font-semibold text-center text-primary mb-10">
                {__("welcome.back")}
              </h3>
              {error && (
                <Alert
                  className="mt-2"
                  message={__("username.password.invalid")}
                  type="error"
                />
              )}
              <Form
                name="basic"
                layout="vertical"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                className="flex flex-col mt-2"
              >
                <Form.Item
                  label={__("email")}
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: __("please.enter.email"),
                    },
                  ]}
                >
                  <Input
                    className="rounded py-2 px-3 leading-tight mt-2 appearance-none border-2 border-slate-100
                      focus:outline-none focus:bg-white focus:border-blue-600 text-gray-600"
                  />
                </Form.Item>
                <Form.Item
                  label={__("Password")}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: __("please.enter.password"),
                    },
                  ]}
                >
                  <Input.Password
                    className="rounded py-2 px-3 leading-tight  appearance-none border-2 border-slate-100
                      focus:outline-none focus:bg-white focus:border-blue-600 text-gray-600"
                  />
                </Form.Item>
                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  className="inline-block"
                >
                  <a
                    className="text-primary rtl:font-kufi"
                    href="/forgot-password"
                  >
                    {__("forget.password")}
                  </a>
                  <span className="span-pag text-sm font-medium">
                    <Link to="/register">&nbsp;{__("register")}</Link>
                  </span>
                </Form.Item>
                <button
                  className="bg-primary hover:bg-primary-light transition duraion-100 p-2 text-white rtl:font-kufi rounded"
                  type="submit"
                >
                  {__("login")}
                </button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
